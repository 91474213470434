import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { keyframes } from "@emotion/react";
import { Box } from "@mui/joy";
import usePalette from "../../theme/use_palette";

const progressAnimation = keyframes`
  0% {
    width: 0%;
    opacity: 1;
  }
  50% {
    width: 70%;
    opacity: 1;
  }
  90% {
    width: 95%;
    opacity: 1;
  }
  100% {
    width: 100%;
    opacity: 0;
  }
`;

const glowAnimation = keyframes`
  0% {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
  50% {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  100% {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
`;

const RouteChangeLoader: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const palette = usePalette();

  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, [location]);

  if (!isLoading) return null;

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        height: "5px",
        zIndex: 9999,
        "& .progress-bar": {
          height: "100%",
          background: `linear-gradient(to right, ${palette.primaryMain}, ${palette.primaryLight})`,
          animation: `${progressAnimation} 1s ease-out, ${glowAnimation} 1s infinite`,
          transformOrigin: "left",
          borderRadius: "2px",
          boxShadow: `0 0 10px ${palette.primaryMain}40`,
        },
      }}
    >
      <div className="progress-bar" />
    </Box>
  );
};

export default RouteChangeLoader;
