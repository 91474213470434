import React, { useEffect, useState } from "react";
import {
  Drawer,
  SwipeableDrawer, // Import SwipeableDrawer
  List,
  useTheme,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import EditIcon from "@mui/icons-material/Edit";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { Divider } from "@mui/material";
import StyledText from "../../../text/styled_text";
import { useTranslation } from "react-i18next";
import PanToolIcon from "@mui/icons-material/PanTool";
import AddIcon from "@mui/icons-material/Add";
import DrawerListItem from "../drawer_list_item";
import CollapsibleDrawerSection from "../collapsible_drawer_section";
import SettingsIcon from "@mui/icons-material/Settings";
import { ROUTES, generateRoute } from "../../../../routes/def";
import { DRAWER_WIDTH } from "../../../../hooks/drawer_pinned_hook";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store";
import { useNetworkDetails } from "../../../../hooks/manager/network_details_hook";
import PushPinIcon from "@mui/icons-material/PushPin";
import { Box, Tooltip } from "@mui/joy";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate } from "react-router-dom";
import { isColorDark } from "../../../../utils/manager/color";
import { selectMainColor } from "../../../../redux/features/managerThemeSlice";
import { useDispatch } from "react-redux";
import { toggleSection } from "../../../../redux/features/drawer/drawerSectionsSlice";
import SendIcon from "@mui/icons-material/Send";
import MenuIcon from "@mui/icons-material/Menu"; // Import MenuIcon for the mobile button
import useDrawerHeight from "../../../../hooks/drawer_height_hook";
import usePalette from "../../../../theme/use_palette";
import BuildIcon from "@mui/icons-material/Build";

interface DrawerComponentProps {
  eventID: string;
  handlePinned: (isPinned: boolean) => void;
}

const DrawerComponent: React.FC<DrawerComponentProps> = ({
  eventID,
  handlePinned,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch: AppDispatch = useDispatch();
  const palette = usePalette();

  // State Variables
  const [isPinned, setIsPinned] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false); // New state for mobile drawer
  const mainColor = useSelector(selectMainColor);

  // Redux State
  const { event } = useSelector((state: RootState) => state.eventDetail);
  const sectionsState = useSelector((state: RootState) => state.drawerSections);
  const { isPinned: initialIsPinned } = useSelector(
    (state: RootState) => state.drawerPinned
  );
  const main_color = useSelector(selectMainColor);
  const { user: currentUser } = useSelector((state: RootState) => state.auth);

  // Custom Hooks
  const drawerHeight = useDrawerHeight();
  const { network } = useNetworkDetails();

  useEffect(() => {
    if (initialIsPinned !== null && !isInitialized) {
      setIsPinned(initialIsPinned);
      setIsInitialized(true);
    }
  }, [initialIsPinned, isInitialized]);

  // Add cleanup
  useEffect(() => {
    return () => {
      setIsInitialized(false);
    };
  }, []);

  // Adjust isExtended logic
  const isExtended = isScreenSmall ? mobileDrawerOpen : isHovered || isPinned;

  const backgroundColor = isExtended ? palette.spaceCadetExtraDark : mainColor;
  const textColorBasedOnBg = isColorDark(backgroundColor) ? "white" : "black";

  // Drawer content extracted into a function for reuse
  const drawerContent = (
    <Box
      sx={{
        position: "relative",
        height: "100%",
      }}
    >
      <Box
        sx={{
          color: backgroundColor,
          textAlign: "left",
          fontWeight: "bold",
          fontSize: "1.2rem",
          width: !isExtended ? "0px" : "100%",
          height: "100px",
          pb: 0,
          pr: 0,
          pt: 0,
          backgroundColor: backgroundColor,
        }}
      >
        <div
          style={{
            display: isExtended ? "block" : "none",
            whiteSpace: "nowrap",
            paddingLeft: "16px",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "0px",
              left: "8px",
            }}
          >
            <IconButton
              onClick={() => {
                navigate(ROUTES.MANAGER_DASHBOARD);
              }}
            >
              <ArrowBackIcon
                sx={{
                  margin: 0,
                  fontSize: 18,
                  color: textColorBasedOnBg,
                  transition: "all 0.3s",
                  "&:hover": {
                    color: palette.greyLight,
                    transform: "translateX(-2px)",
                  },
                }}
              />
            </IconButton>
          </Box>
          <StyledText
            level="h3"
            color={textColorBasedOnBg}
            fontSize={20}
            fontWeight={600}
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              width: "95%",
              pt: 4,
              pointer: "cursor",
            }}
            forceTextPointer
            onClick={() => {
              navigate(
                generateRoute(ROUTES.MANAGE_EVENT, { eventId: eventID })
              );
            }}
          >
            {event?.name || "Event Name"}
          </StyledText>
          <StyledText
            level="h3"
            color={textColorBasedOnBg}
            fontSize={14}
            fontWeight={400}
          >
            {t("manage_event.drawer.title")}
          </StyledText>
        </div>
      </Box>
      <List
        sx={{
          pb: "128px",
          mt: 1,
        }}
      >
        <CollapsibleDrawerSection
          planEnrollment={network?.plan_enrollment!}
          title={t("manage_event.drawer.manage.title")}
          icon={<PanToolIcon />}
          drawerExtended={isExtended}
          isOpen={sectionsState["event_overview"] || false}
          toggleSection={() =>
            dispatch(toggleSection({ sectionId: "event_overview" }))
          }
          subItems={[
            {
              title: t("manage_event.drawer.manage.overview"),
              navigateTo: generateRoute(ROUTES.MANAGE_EVENT, {
                eventId: eventID,
              }),
              clickable: true,
            },
            {
              title: t("manage_event.drawer.manage.ticket_releases"),
              navigateTo: generateRoute(ROUTES.MANAGE_EVENT_TICKET_RELEASES, {
                eventId: eventID,
              }),
              clickable: true,
            },
            {
              title: t("manage_event.drawer.manage.attendees"),
              navigateTo: generateRoute(ROUTES.MANAGE_EVENT_TICKETS, {
                eventId: eventID,
              }),
              clickable: true,
            },
            {
              title: t("manage_event.drawer.manage.form_responses"),
              navigateTo: generateRoute(ROUTES.MANAGE_EVENT_RESPONSES, {
                eventId: eventID,
              }),
              clickable: true,
            },
            {
              title: t("manage_event.drawer.manage.check_in"),
              navigateTo: generateRoute(ROUTES.TICKET_SCANNER, {
                eventId: eventID,
              }),
              clickable: true,
              requiredFeature: "check_in",
            },
          ]}
        />
        <Divider sx={{ my: 0.25 }} />
        <CollapsibleDrawerSection
          planEnrollment={network?.plan_enrollment!}
          icon={<EditIcon />}
          title={t("form.button_edit")}
          isOpen={sectionsState["event_edit"] || false}
          toggleSection={() =>
            dispatch(toggleSection({ sectionId: "event_edit" }))
          }
          drawerExtended={isExtended}
          subItems={[
            {
              title: t("manage_event.drawer.edit.event"),
              navigateTo: generateRoute(ROUTES.EDIT_EVENT, {
                eventId: eventID,
              }),
              clickable: true,
            },
            {
              title: t("manage_event.drawer.edit.ticket_releases"),
              navigateTo: generateRoute(ROUTES.EDIT_EVENT_TICKET_RELEASES, {
                eventId: eventID,
              }),
              clickable: true,
            },
            {
              title: t("manage_event.drawer.edit.form"),
              navigateTo: generateRoute(ROUTES.EDIT_EVENT_FORM, {
                eventId: eventID,
              }),
              clickable: true,
            },
            {
              title: t("manage_event.drawer.edit.event_page"),
              navigateTo: generateRoute(
                ROUTES.EDIT_EVENT_LANDING_PAGE_SETTINGS,
                {
                  eventId: eventID,
                }
              ),
              clickable: true,
            },
          ]}
        />
        <Divider sx={{ my: 0.25 }} />
        <CollapsibleDrawerSection
          planEnrollment={network?.plan_enrollment!}
          icon={<SendIcon />}
          title={t("manage_event.drawer.send_outs.title")}
          drawerExtended={isExtended}
          requiredFeature="send_outs"
          isOpen={sectionsState["event_send_outs"] || false}
          toggleSection={() =>
            dispatch(toggleSection({ sectionId: "event_send_outs" }))
          }
          subItems={[
            {
              title: t("manage_event.drawer.send_outs.list"),
              navigateTo: generateRoute(ROUTES.MANAGE_SEND_OUT_LIST, {
                eventId: eventID,
              }),
              clickable: true,
            },
            {
              title: t("manage_event.drawer.send_outs.new"),
              navigateTo: generateRoute(ROUTES.MANAGE_SEND_OUT_NEW, {
                eventId: eventID,
              }),
              clickable: true,
            },
          ]}
        />
        <CollapsibleDrawerSection
          planEnrollment={network?.plan_enrollment!}
          icon={<MailIcon />}
          title={t("manage_event.drawer.emails.title")}
          drawerExtended={isExtended}
          requiredFeature="custom_emails"
          isOpen={sectionsState["emails"] || false}
          toggleSection={() => dispatch(toggleSection({ sectionId: "emails" }))}
          subItems={[
            {
              title: t("manage_event.drawer.emails.templates"),
              navigateTo: generateRoute(ROUTES.MANAGE_EVENT_EMAIL_TEMPLATES, {
                eventId: eventID,
              }),
              clickable: true,
              requiredFeature: "custom_emails",
            },
          ]}
        />
        <Divider sx={{ my: 0.25 }} />
        <CollapsibleDrawerSection
          planEnrollment={network?.plan_enrollment!}
          icon={<AttachMoneyIcon />}
          title={t("manage_event.drawer.economy.title")}
          drawerExtended={isExtended}
          isOpen={sectionsState["event_economy"] || false}
          toggleSection={() =>
            dispatch(toggleSection({ sectionId: "event_economy" }))
          }
          subItems={[
            {
              title: t("manage_event.drawer.economy.sales_report"),
              navigateTo: generateRoute(ROUTES.MANAGE_EVENT_ECONOMY, {
                eventId: eventID,
              }),
              clickable: true,
              requiredFeature: "sales_reports",
            },
            // other subItems
          ]}
        />{" "}
        <Divider sx={{ my: 0.25 }} />
        <CollapsibleDrawerSection
          planEnrollment={network?.plan_enrollment!}
          title={t("manage_event.drawer.settings.title")}
          icon={<SettingsIcon />}
          drawerExtended={isExtended}
          isOpen={sectionsState["event_settings"] || false}
          toggleSection={() =>
            dispatch(toggleSection({ sectionId: "event_settings" }))
          }
          subItems={[
            {
              title: t("manage_event.drawer.settings.financial"),
              navigateTo: generateRoute(
                ROUTES.MANAGE_EVENT_SETTINGS_FINANCIAL,
                {
                  eventId: eventID,
                }
              ),
              clickable: true,
            },
            {
              title: t("manage_event.drawer.settings.domains"),
              navigateTo: generateRoute(ROUTES.MANAGE_EVENT_SETTINGS_DOMAIN, {
                eventId: eventID,
              }),
              clickable: true,
              requiredFeature: "custom_domains",
            },
          ]}
        />
        <CollapsibleDrawerSection
          planEnrollment={network?.plan_enrollment!}
          title={t("manage_event.drawer.tools.title")}
          icon={<BuildIcon />}
          drawerExtended={isExtended}
          isOpen={sectionsState["event_tools"] || false}
          toggleSection={() =>
            dispatch(toggleSection({ sectionId: "event_tools" }))
          }
          subItems={[
            {
              title: t("manage_event.drawer.tools.seating_plan"),
              navigateTo: generateRoute(ROUTES.EVENT_TOOL_SEATING_PLAN, {
                eventId: eventID,
              }),
              clickable: true,
            },
            {
              title: t("manage_event.drawer.tools.meal_management"),
              navigateTo: generateRoute(ROUTES.EVENT_TOOL_MEAL_MANAGEMENT, {
                eventId: eventID,
              }),
              clickable: true,
            },
            {
              title: t("manage_event.drawer.tools.embed_event"),
              navigateTo: generateRoute(ROUTES.EVENT_TOOL_EMBED_EVENT, {
                eventId: eventID,
              }),
              clickable: true,
            },
          ]}
        />
        <Divider sx={{ my: 0.25 }} />
        <DrawerListItem
          icon={<AddIcon />}
          text={t("manage_event.add_ticket_release")}
          navigateTo={generateRoute(ROUTES.EDIT_EVENT_ADD_TICKET_RELEASE, {
            eventId: eventID,
          })}
          isSelected={
            location.pathname ===
            generateRoute(ROUTES.EDIT_EVENT_ADD_TICKET_RELEASE, {
              eventId: eventID,
            })
          } // Pass isSelected prop
        />
      </List>
      {isExtended && !isScreenSmall && (
        <Box
          sx={{
            position: "absolute",
            top: "0",
            right: "8px",
          }}
        >
          <IconButton
            onClick={() => {
              setIsPinned(!isPinned);
              handlePinned(!isPinned);
            }}
          >
            <Tooltip title={isPinned ? "Unpin" : "Pin"}>
              <PushPinIcon
                color={isPinned ? "success" : "primary"}
                style={{ transform: "rotate(45deg)" }}
              />
            </Tooltip>
          </IconButton>
        </Box>
      )}
    </Box>
  );

  return (
    <>
      {isScreenSmall ? (
        <>
          {/* Mobile View: SwipeableDrawer */}
          <SwipeableDrawer
            open={mobileDrawerOpen}
            onClose={() => setMobileDrawerOpen(false)}
            onOpen={() => setMobileDrawerOpen(true)}
            swipeAreaWidth={30}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              [`& .MuiDrawer-paper`]: {
                width: DRAWER_WIDTH,
                backgroundColor: main_color,
                overflowX: "hidden",
                boxSizing: "border-box",
                "&::-webkit-scrollbar": {
                  width: "0px",
                },
                scrollbarWidth: "none",
              },
            }}
          >
            {drawerContent}
          </SwipeableDrawer>
          {/* Mobile Drawer Open Button */}
          <IconButton
            onClick={() => setMobileDrawerOpen(true)}
            sx={{
              position: "fixed",
              bottom: 64,
              left: 16,
              zIndex: 1300, // Ensure it appears above other elements
              backgroundColor: main_color,
              color: textColorBasedOnBg,
              "&:hover": {
                backgroundColor: backgroundColor,
              },
            }}
          >
            <MenuIcon />
          </IconButton>
        </>
      ) : (
        // Desktop View: Permanent Drawer
        <Drawer
          open={isExtended}
          variant="permanent"
          onMouseEnter={() => !isPinned && setIsHovered(true)}
          onMouseLeave={() => !isPinned && setIsHovered(false)}
          sx={{
            position: "relative",
            top: "64px",
            flexShrink: 0,
            transition: theme.transitions.create("width", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
            [`& .MuiDrawer-paper`]: {
              height: drawerHeight,
              width: isExtended ? DRAWER_WIDTH : theme.spacing(7),
              backgroundColor: main_color,
              transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
              overflowX: "hidden",
              boxSizing: "border-box",
              "&::-webkit-scrollbar": {
                width: "0px",
              },
              scrollbarWidth: "none",
            },
          }}
        >
          {drawerContent}
        </Drawer>
      )}
    </>
  );
};

export default DrawerComponent;
