import React from "react";
import {
  Box,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
  Badge,
} from "@mui/joy";
import { ITicketType } from "../../../types";
import usePalette, { useIsDarkTheme } from "../../../theme/use_palette";
import {
  ShoppingCartItem,
  addTicket,
  confirmSwitchRelease,
  promptSwitchRelease,
  removeTicket,
  setCurrentTicketReleaseId,
  switchEvent,
} from "../../../redux/features/ticketOrderSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { toast } from "react-toastify";
import { numberOfTotalTicketRequestInBasket } from "../../../utils/ticket_types";
import useMediaQuery from "@mui/material/useMediaQuery";
import InfoIcon from "@mui/icons-material/Info";
import {
  selectAccentColor,
  selectMainColor,
} from "../../../redux/features/managerThemeSlice";
import {
  adjustTransparency,
  darkerColor,
  isColorDark,
  lightenColor,
} from "../../../utils/manager/color";
import StyledButton from "../../buttons/styled_button";
import StyledText from "../../text/styled_text";
import zIndex from "@mui/material/styles/zIndex";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useTranslation } from "react-i18next";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";

interface TicketTypeProps {
  ticketType: ITicketType;
  maxTicketsPerUser: number;
}

const TicketType: React.FC<TicketTypeProps> = ({
  ticketType,
  maxTicketsPerUser,
}) => {
  const palette = usePalette();
  const { items } = useSelector((state: RootState) => state.ticketOrder) as {
    items: ShoppingCartItem[];
  };
  const [plusDisabled, setPlusDisabled] = React.useState<boolean>(false);
  const theme = useTheme();
  const { t } = useTranslation();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const mainColor = useSelector(selectMainColor) || palette.primaryMain;
  const accentColor = useSelector(selectAccentColor) || palette.primaryMain;
  const darkMode = useIsDarkTheme();

  const { currentTicketReleaseId, currentEventId } = useSelector(
    (state: RootState) => state.ticketOrder
  );

  const dispatch: AppDispatch = useDispatch();

  const handleAddTicket = (ticket: ITicketType) => {
    if (
      currentTicketReleaseId &&
      currentTicketReleaseId !== ticket.ticket_release_id
    ) {
      dispatch(promptSwitchRelease(ticket));
      if (currentEventId !== ticket.event_id) {
        dispatch(switchEvent(ticket.event_id));
        dispatch(confirmSwitchRelease());
      }
    } else {
      if (!currentTicketReleaseId) {
        dispatch(
          setCurrentTicketReleaseId({
            ticketReleaseId: ticket.ticket_release_id!,
            eventId: ticket.event_id,
          })
        );
      }

      const numberOfTotalTickets = numberOfTotalTicketRequestInBasket(
        items,
        ticket.ticket_release_id!
      );
      if (numberOfTotalTickets >= maxTicketsPerUser) {
        toast.error(
          t("event.ticket_release.max_tickets_error", {
            count: maxTicketsPerUser,
          })
        );
        return;
      }

      if (numberOfTotalTickets + 1 >= maxTicketsPerUser) {
        setPlusDisabled(true);
      } else {
        setPlusDisabled(false);
      }

      dispatch(addTicket(ticket));
    }
  };

  const handleRemoveTicket = (ticket: ITicketType) => {
    setPlusDisabled(false);
    dispatch(removeTicket(ticket));
  };

  const quantity = items.filter(
    (item) => item.ticket.id === ticketType.id
  ).length;

  const calculateFontSize = (str: string) => {
    const length = str.length;
    if (length <= 10) {
      return isScreenSmall ? 16 : 24;
    } else if (length <= 20) {
      return isScreenSmall ? 14 : 20;
    } else {
      return isScreenSmall ? 12 : 18;
    }
  };

  const buttonBgColor = darkMode ? palette.offWhite : palette.greyLight;

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        maxWidth: 600,
        margin: "16px auto",
        backgroundColor: darkMode
          ? adjustTransparency(palette.alwaysBlack, 0.5)
          : adjustTransparency(palette.isabellineExtraDark, 0.1),
        borderRadius: "12px",
        border: `2px solid ${adjustTransparency(
          palette.primaryExtraLight,
          0.6
        )}`,
        overflow: "hidden",
        display: "flex",
        flexDirection: "row",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.08)",
        height: "auto",
        transition: "all 0.2s ease-in-out",
        "&:hover": {
          boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.12)",
          borderColor: palette.primaryMain,
        },
      }}
      role="region"
      aria-label={`${ticketType.name} ticket selection`}
    >
      {/* Left Side - Ticket Info */}
      <Box
        sx={{
          flex: 1,
          padding: 2.5,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: 1,
        }}
      >
        <Box display="flex" alignItems="center" gap={1}>
          <LocalOfferIcon sx={{ color: palette.primaryMain, fontSize: 24 }} />
          <Typography
            fontWeight={700}
            fontSize={calculateFontSize(ticketType.name)}
            sx={{
              overflowWrap: "break-word",
              maxWidth: "100%",
              color: palette.primaryDark,
              lineHeight: 1.2,
            }}
          >
            {ticketType.name}
          </Typography>
          {ticketType.description && (
            <Tooltip
              title={ticketType.description}
              placement="right"
              arrow
              variant="soft"
              sx={{
                maxWidth: 400,
              }}
            >
              <InfoIcon
                sx={{
                  color: palette.primaryDark,
                  cursor: "help",
                  fontSize: 20,
                  opacity: 0.8,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            </Tooltip>
          )}
        </Box>
        <StyledText
          fontWeight={600}
          fontSize={20}
          sx={{
            color: palette.primaryDark,
            display: "flex",
            alignItems: "center",
            gap: 0.5,
          }}
        >
          {ticketType.price === 0
            ? t("event.ticket_release.checkout.free")
            : `${ticketType.price} SEK`}
        </StyledText>
      </Box>

      {/* Right Side - Quantity Controls */}
      <Box
        sx={{
          width: 90,
          color: isColorDark(accentColor) ? palette.white : palette.black,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          bgcolor: adjustTransparency(palette.primaryExtraLight, 0.15),
          borderLeft: `2px dashed ${adjustTransparency(
            palette.primaryMain,
            0.3
          )}`,
        }}
      >
        {/* Quantity Controls */}
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "grid",
            gridTemplateRows: "1fr auto 1fr",
            gap: 0.5,
            padding: 0.5,
          }}
        >
          {/* Plus Button */}
          <IconButton
            aria-label="Add ticket"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
              transition: "all 0.2s",
              borderRadius: "8px",
              bgcolor: adjustTransparency(buttonBgColor, 0.7),
              "&:hover": {
                bgcolor: !plusDisabled ? palette.primaryExtraLight : undefined,
              },
              "&:disabled": {
                opacity: 0.5,
              },
            }}
            onClick={() => {
              !plusDisabled && handleAddTicket(ticketType);
            }}
            disabled={plusDisabled}
          >
            <AddIcon
              sx={{
                color: plusDisabled ? palette.greyDark : palette.primaryDark,
                fontSize: 24,
              }}
            />
          </IconButton>

          {/* Count Display */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "40px",
              borderRadius: "8px",
              backgroundColor: palette.white,
              border: `1px solid ${adjustTransparency(
                palette.primaryMain,
                0.2
              )}`,
              "& .MuiTypography-root": {
                fontWeight: 700,
                fontSize: 20,
                color: palette.primaryDark,
              },
            }}
          >
            <StyledText
              level="body-lg"
              color={palette.primaryDark}
              fontWeight={700}
              fontSize={22}
            >
              {quantity || 0}
            </StyledText>
          </Box>

          {/* Minus Button */}
          <IconButton
            aria-label="Remove ticket"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
              transition: "all 0.2s",
              borderRadius: "8px",
              bgcolor: adjustTransparency(buttonBgColor, 0.7),
              "&:hover": {
                bgcolor: quantity > 0 ? palette.primaryExtraLight : undefined,
              },
              "&:disabled": {
                opacity: 0.5,
              },
            }}
            disabled={quantity === 0}
            onClick={() => {
              quantity > 0 && handleRemoveTicket(ticketType);
            }}
          >
            <RemoveIcon
              sx={{
                color: quantity === 0 ? palette.greyDark : palette.primaryDark,
                fontSize: 24,
              }}
            />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default TicketType;
