import React from "react";
import { Box, Stack } from "@mui/joy";
import StyledButton from "../buttons/styled_button";
import { ROUTES } from "../../routes/def";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import StyledText from "../text/styled_text";
import BookDemoModal from "./book_a_demo";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { motion, useInView } from "framer-motion";
import usePalette from "../../theme/use_palette";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const GetStarted: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const palette = usePalette();

  const ref1 = React.useRef(null);
  const ref2 = React.useRef(null);
  const inView1 = useInView(ref1, { once: true });
  const inView2 = useInView(ref2, { once: true });

  const { isLoggedIn } = useSelector((state: RootState) => state.auth);

  return (
    <Box sx={{ textAlign: "center" }}>
      <Stack
        spacing={isSmallScreen ? 3 : 6}
        direction={isSmallScreen ? "column" : "row"}
        justifyContent={isSmallScreen ? "flex-start" : "center"}
        alignItems={isSmallScreen ? "center" : "center"}
      >
        <motion.div
          ref={ref1}
          initial={{ opacity: 0, y: -20 }}
          animate={inView1 ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.5 }}
        >
          <Box>
            <StyledText
              fontSize={32}
              color={palette.primaryMain}
              fontWeight={700}
              level="h2"
            >
              {t("main_page.social_proof.ready")}
            </StyledText>
          </Box>
        </motion.div>
        <motion.div
          ref={ref2}
          initial={{ opacity: 0, y: 20 }}
          animate={inView2 ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <Box
            sx={{
              maxWidth: 500,
              height: 250,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <StyledText
              fontSize={20}
              color={palette.charcoal}
              fontWeight={500}
              level="body-md"
              sx={{
                textWrap: "balance",
              }}
            >
              {t("main_page.social_proof.description")}
            </StyledText>
            <Stack
              spacing={2}
              direction="row"
              justifyContent="center"
              sx={{
                mt: 2,
              }}
            >
              <BookDemoModal />
              {!isLoggedIn && (
                <StyledButton
                  size="md"
                  onClick={() => navigate(ROUTES.LOGIN)}
                  color={palette.black}
                  fs={22}
                >
                  {t("main_page.social_proof.cta")}
                </StyledButton>
              )}
            </Stack>
          </Box>
        </motion.div>
      </Stack>
    </Box>
  );
};

export default GetStarted;
