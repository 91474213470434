import { Button, ButtonProps, Typography, styled } from "@mui/joy";
import StyledText from "../text/styled_text";
import usePalette from "../../theme/use_palette"; // Updated import
import { useSelector } from "react-redux";
import {
  selectAccentColor,
  selectMainColor,
} from "../../redux/features/managerThemeSlice";
import {
  adjustTransparency,
  isColorDark,
  lightenColor,
} from "../../utils/manager/color";

interface StyledButtonExtension extends Omit<ButtonProps, "color"> {
  children: React.ReactNode;
  bgColor?: string; // Renamed to avoid conflict with Button's 'color' prop
  color_?: string; // Renamed for consistency
  size: "sm" | "md" | "lg";
  accentColor?: string; // Add accentColor prop
  bgHoverColor?: string | null;
}

interface StyledButtonProps {
  children: React.ReactNode;
  onClick?: (() => void) | ((e: any) => void);
  bgColor?: string;
  bgHoverColor?: string;
  color?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  size: "sm" | "md" | "lg";
  startDecorator?: React.ReactNode;
  textColor?: string;
  type?: "button" | "submit" | "reset";
  sx?: any;
  fs?: number;
  endDecorator?: React.ReactNode; // Add endDecorator prop
  textSx?: any; // Add textSx prop
}

const ButtonStyled = styled(Button)<StyledButtonExtension>(
  ({ theme, disabled, bgColor, color_, accentColor, bgHoverColor }) => {
    const palette = usePalette(); // Use new palette
    const hoverColor =
      accentColor && isColorDark(accentColor)
        ? lightenColor(accentColor, 0.5)
        : accentColor || palette.primaryMain;

    const hoverTextColor =
      hoverColor && isColorDark(hoverColor)
        ? palette.accentMain
        : palette.black;

    return {
      transition: "all 0.2s ease-in-out",
      backgroundColor: bgColor,
      border: `2px solid`,
      borderColor:
        adjustTransparency(hoverColor, 0.2) ||
        adjustTransparency(palette.primaryMain, 0.2),

      "&:hover": {
        borderColor: palette.charcoal,
        backgroundColor: lightenColor(palette.primaryMain, 0.2),
        color: hoverTextColor + " !important",
      },
      color: color_ ? color_ : palette.primaryMain,
    };
  }
);

const StyledButton: React.FC<StyledButtonProps> = ({
  children,
  onClick,
  bgColor = null,
  bgHoverColor = null,
  size,
  style,
  type = "button",
  color,
  disabled = false,
  startDecorator,
  textColor,
  sx = {},
  fs,
  endDecorator = null, // Destructure endDecorator
  textSx = {}, // Add textSx
}) => {
  const palette = usePalette(); // Use new palette
  const mainColor = palette.primaryMain;

  const backgroundColor = disabled
    ? palette.greyLight
    : !bgColor
    ? mainColor
    : bgColor;

  return (
    <ButtonStyled
      variant={"outlined"}
      onClick={onClick}
      type={type}
      disabled={disabled}
      size={size}
      startDecorator={startDecorator}
      style={style}
      endDecorator={endDecorator} // Pass endDecorator to ButtonStyled
      bgColor={backgroundColor}
      bgHoverColor={bgHoverColor}
      color_={color}
      accentColor={palette.primaryMain} // Pass accentColor to ButtonStyled
      sx={{
        ...sx,
        borderColor:
          backgroundColor === palette.primaryMain
            ? adjustTransparency(palette.greyLight, 0.5)
            : adjustTransparency(palette.greyLight, 0.5),
      }}
      aria-label={"button"}
    >
      <StyledText
        level="body-md"
        color={disabled ? palette.greyDark : color}
        fontSize={fs}
        fontWeight={600}
        forceTextPointer
        sx={textSx} // Pass textSx to StyledText
      >
        {children}
      </StyledText>
    </ButtonStyled>
  );
};

export default StyledButton;
