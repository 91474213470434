import React from "react";
import { Helmet } from "react-helmet";
import { IEvent } from "../../types";

interface PublicEventSEOProps {
  event: IEvent;
}

const PublicEventSEO: React.FC<PublicEventSEOProps> = ({ event }) => {
  return (
    <>
      <Helmet>
        <title>{event.name} - Tessera</title>
        <meta
          name="description"
          content={`Join us for ${event.name} on ${event.date}. Location: ${event.location}.`}
        />
        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href={
            event.details?.slug
              ? `https://${event.details.slug}.tesseratickets.se`
              : `https://tesseratickets.se/events/${event.id}`
          }
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Event",
            name: event.name,
            startDate: event.date,
            location: {
              "@type": "Place",
              name: event.location,
            },
            description: event.description,
          })}
        </script>
      </Helmet>
    </>
  );
};

export default PublicEventSEO;
