import { PayloadAction } from "@reduxjs/toolkit";
import { IEvent, IEventDomainSettingsPostReq } from "../../types";
import { call, put, takeLatest } from "redux-saga/effects";
import { ApiResponse, putApi } from "../../utils/api/api";
import { toast } from "react-toastify";
import ApiRoutes from "../../routes/backend_routes";
import {
  updateEventDomainFailure,
  updateEventDomainRequest,
  updateEventDomainSuccess,
} from "../features/eventDomainSlice";
import { handleApiError } from "../../utils/error_handlers";

function* eventDomainSaga(
  action: PayloadAction<{
    eventID: number;
    domainSettings: IEventDomainSettingsPostReq;
  }>
): Generator<any, void, any> {
  try {
    const { eventID, domainSettings } = action.payload;

    const response: ApiResponse<{
      event: IEvent;
    }> = yield call(
      putApi,
      ApiRoutes.generateRoute(ApiRoutes.MANAGER_EVENT_DETAILS, {
        eventID,
      }),
      domainSettings,
      true,
      true
    );

    if (response.status === "success") {
      toast.success("Event domain settings updated successfully");
      yield put(updateEventDomainSuccess(null));
    }
  } catch (error: any) {
    const errorMessage = error.response?.data?.error || "An error occurred";
    handleApiError(error, "Failed to update event domain settings", false);

    yield put(updateEventDomainFailure(errorMessage));
  }
}

function* watchEventDomainSaga() {
  yield takeLatest(updateEventDomainRequest.type, eventDomainSaga);
}

export default watchEventDomainSaga;
