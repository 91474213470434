import React from "react";
import {
  Box,
  Chip,
  Dropdown,
  Grid,
  IconButton,
  Link,
  ListItemDecorator,
  Menu,
  MenuButton,
  MenuItem,
  Sheet,
  Stack,
  Tooltip,
} from "@mui/joy";
import { ITicketRelease } from "../../../types";
import usePalette, { useIsDarkTheme } from "../../../theme/use_palette";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useMediaQuery, useTheme } from "@mui/material";
import {
  ticketReleaseHasClosed,
  ticketReleaseHasNotOpened,
  ticketReleaseHasOpened,
} from "../../../utils/event_open_close";
import TicketReleaseHasOpened from "./ticket_release_has_opened";
import TicketReleaseHasClosed from "./ticket_release_has_closed";
import TicketReleaseHasNotOpened from "./ticket_release_has_not_opened";
import TicketReleaseSoldOut from "./ticket_release_sold_out";
import StyledText from "../../text/styled_text";
import InformationModal from "../../modal/information";
import { Trans, useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import TicketReleaseMethodDetail from "./ticket_release_method/detailed_info";
import { selectAccentColor } from "../../../redux/features/managerThemeSlice";
import { ticketReleaseRequiresAccount } from "../../../utils/manage_event/can_edit_payment_deadline";
import { adjustTransparency } from "../../../utils/manager/color";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FeedbackIcon from "@mui/icons-material/Feedback";
import { ROUTES } from "../../../routes/def";
import { ICalendarEvent } from "../../../utils/calender";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import {
  getGoogleCalendarUrl,
  getICalendarUrl,
  getOutlookCalendarUrl,
} from "../../../utils/calender";

interface TicketReleaseProps {
  ticketRelease: ITicketRelease;
}

const renderTicketReleaseStatus = (
  ticketRelease: ITicketRelease,
  timestamp: number
) => {
  if (ticketRelease.sold_out) {
    return <TicketReleaseSoldOut ticketRelease={ticketRelease} />;
  }

  if (ticketReleaseHasNotOpened(ticketRelease, timestamp)) {
    return <TicketReleaseHasNotOpened ticketRelease={ticketRelease} />;
  } else if (ticketReleaseHasClosed(ticketRelease, timestamp)) {
    return <TicketReleaseHasClosed ticketRelease={ticketRelease} />;
  } else if (ticketReleaseHasOpened(ticketRelease, timestamp)) {
    return <TicketReleaseHasOpened ticketRelease={ticketRelease} />;
  }
};

const TicketRelease: React.FC<TicketReleaseProps> = ({ ticketRelease }) => {
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const { t } = useTranslation();
  const { timestamp } = useSelector((state: RootState) => state.timestamp);
  const { isLoggedIn } = useSelector((state: RootState) => state.auth);
  const theme = useTheme();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const palette = usePalette();

  const reminderEvent: ICalendarEvent = {
    name: ticketRelease.name + " Ticket Release",
    description: ticketRelease.description,
    date: ticketRelease.open,
  };

  const [calendarAnchor, setCalendarAnchor] =
    React.useState<null | HTMLElement>(null);

  const handleCalendarClose = () => {
    setCalendarAnchor(null);
  };

  const isSingleFreeTicket =
    ticketRelease.ticket_types?.length === 1 &&
    ticketRelease.ticket_types[0].price === 0 &&
    ticketRelease.ticket_release_method_detail?.max_tickets_per_user === 1;

  return (
    <Sheet
      variant="outlined"
      sx={{
        backgroundColor: palette.white,
        padding: isScreenSmall ? 2 : 2.5,
        borderRadius: 16,
        width: "100%",
        position: "relative",
        boxShadow: "0 1px 5px rgba(0,0,0,0.5)",
      }}
    >
      {/* Reserved Chip */}
      <Stack
        direction="row"
        gap={1}
        sx={{ position: "absolute", top: 16, right: 16 }}
      >
        {ticketReleaseRequiresAccount(
          ticketRelease.ticket_release_method_detail.ticket_release_method
        ) && (
          <Tooltip
            title={t("event.ticket_release.requires_account_tooltip")}
            placement="top"
            arrow
          >
            <Chip
              sx={{
                bgcolor: adjustTransparency(palette.primaryExtraDark, 0.9),
                p: 1,
                borderRadius: 8,
              }}
            >
              <StyledText
                fontSize={16}
                level="body-lg"
                fontWeight={600}
                color={palette.alwaysWhite}
                onClick={
                  !isLoggedIn
                    ? () => {
                        !isLoggedIn && window.location.assign(ROUTES.LOGIN);
                      }
                    : undefined
                }
                endDecorator={
                  isLoggedIn ? (
                    <CheckCircleIcon
                      sx={{ color: palette.successLight, fontSize: 22 }}
                    />
                  ) : (
                    <FeedbackIcon
                      sx={{ color: palette.alwaysWhite, fontSize: 22 }}
                    />
                  )
                }
              >
                {t("event.ticket_release.requires_account")}
              </StyledText>
            </Chip>
          </Tooltip>
        )}

        {ticketRelease.is_reserved && (
          <Chip
            variant="solid"
            sx={{
              bgcolor: palette.black,
              p: 1,
              borderRadius: 8,
            }}
            size="sm"
          >
            <StyledText
              color={palette.white}
              fontSize={16}
              level="body-sm"
              fontWeight={600}
            >
              {t("event.reserved")}
            </StyledText>
          </Chip>
        )}
        {ticketReleaseHasNotOpened(ticketRelease, timestamp) && (
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Dropdown>
              <MenuButton
                slots={{ root: IconButton }}
                slotProps={{
                  root: {
                    size: "sm",
                    sx: { color: palette.primaryDark },
                  },
                }}
              >
                <Tooltip title={t("event.actions.add_to_calendar")}>
                  <CalendarTodayIcon />
                </Tooltip>
              </MenuButton>
              <Menu placement="bottom-start">
                <MenuItem
                  onClick={() => {
                    window.open(getGoogleCalendarUrl(reminderEvent), "_blank");
                    handleCalendarClose();
                  }}
                >
                  <ListItemDecorator>
                    <CalendarTodayIcon />
                  </ListItemDecorator>
                  {t("event.actions.calendar.google")}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    window.open(getOutlookCalendarUrl(reminderEvent), "_blank");
                    handleCalendarClose();
                  }}
                >
                  <ListItemDecorator>
                    <CalendarTodayIcon />
                  </ListItemDecorator>
                  {t("event.actions.calendar.outlook")}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    const link = document.createElement("a");
                    link.href = getICalendarUrl(reminderEvent);
                    link.download = `${ticketRelease.name}.ics`;
                    link.click();
                    handleCalendarClose();
                  }}
                >
                  <ListItemDecorator>
                    <CalendarTodayIcon />
                  </ListItemDecorator>
                  {t("event.actions.calendar.ical")}
                </MenuItem>
              </Menu>
            </Dropdown>
          </Box>
        )}
      </Stack>

      {/* Description */}
      {ticketRelease.description && (
        <StyledText
          level="body1"
          color={palette.textPrimary}
          fontSize={16}
          fontWeight={500}
        >
          <ReactMarkdown>{ticketRelease.description}</ReactMarkdown>
        </StyledText>
      )}
      {!isSingleFreeTicket && (
        <Grid container gap={1}>
          <Chip
            sx={{
              bgcolor: adjustTransparency(palette.greyLight, 0.4),
            }}
          >
            <StyledText
              fontSize={14}
              level="body-lg"
              fontWeight={500}
              color={palette.charcoal}
            >
              {t("event.ticket_release.max_tickets_per_user", {
                count:
                  ticketRelease.ticket_release_method_detail
                    .max_tickets_per_user,
              })}
            </StyledText>
          </Chip>
          <Chip
            sx={{
              bgcolor: adjustTransparency(palette.greyLight, 0.4),
            }}
          >
            <StyledText
              fontSize={14}
              level="body-lg"
              fontWeight={500}
              color={palette.charcoal}
            >
              {t("event.ticket_release.cancellation")}:{" "}
              {ticketRelease.ticket_release_method_detail.cancellation_policy.replace(
                "_",
                " "
              )}
            </StyledText>
          </Chip>
        </Grid>
      )}

      {/* Ticket Release Status */}
      <Box sx={{ mt: 0.5 }}>
        {renderTicketReleaseStatus(ticketRelease, timestamp!)}
      </Box>

      {/* Ticket Release Method Detail */}
      <Box sx={{ mt: 2 }}>
        <TicketReleaseMethodDetail ticketRelease={ticketRelease} />
      </Box>

      {/* Ticket Release Method Info */}
      {!ticketReleaseHasClosed(ticketRelease, timestamp!) && (
        <Box sx={{ mt: 2 }}>
          <StyledText level="body2" color={palette.greyLight} fontSize={15}>
            <Trans
              i18nKey="event.ticket_release.method"
              values={{
                method:
                  ticketRelease.ticket_release_method_detail
                    ?.ticket_release_method?.descriptive_name,
              }}
            >
              This release uses
              <Link
                target="_blank"
                onClick={() => setModalIsOpen(true)}
                sx={{ color: palette.linkMain, textDecoration: "underline" }}
              >
                {
                  ticketRelease.ticket_release_method_detail
                    ?.ticket_release_method?.descriptive_name
                }
              </Link>
            </Trans>
          </StyledText>
          {ticketRelease.ticket_release_method_detail?.ticket_release_method
            ?.id === 4 && (
            <StyledText
              level="body2"
              color={palette.greyLight}
              fontSize={14}
              sx={{ fontStyle: "italic", mt: 1 }}
            >
              {" - "}
              {ticketRelease.ticket_release_method_detail.method_description}
            </StyledText>
          )}
        </Box>
      )}

      {/* Information Modal */}
      <InformationModal
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        title={
          ticketRelease.ticket_release_method_detail?.ticket_release_method
            ?.descriptive_name || ""
        }
      >
        <StyledText
          level="body1"
          color={palette.textPrimary}
          fontSize={16}
          fontWeight={500}
        >
          {
            ticketRelease.ticket_release_method_detail?.ticket_release_method
              ?.description
          }
        </StyledText>
      </InformationModal>
    </Sheet>
  );
};

export default TicketRelease;
