import React, { useState } from "react";
import { Box, IconButton, Stack } from "@mui/joy";
import DeleteIcon from "@mui/icons-material/Delete";
import { IEventFormField } from "../../../../types";
import FormFile, { ExistingFilePreview } from "../../../forms/form_file";
import { Visibility } from "@mui/icons-material";
import StyledText from "../../../text/styled_text";
import usePalette from "../../../../theme/use_palette";
import { useTranslation } from "react-i18next";

interface ImageFieldProps {
  field: IEventFormField;
  formik: any;
}

const ImageField: React.FC<ImageFieldProps> = ({ field, formik }) => {
  // 1) Extract the current values from Formik ( { existingUrl, remove, file } ).
  const fieldKey = field.id.toString();
  const fieldValue = formik.values[fieldKey] || {};
  const { existingUrl, remove } = fieldValue;
  const palette = usePalette();
  const { t } = useTranslation();

  // 2) Local state to track which image we’re previewing (if any).
  //    We only need a single piece of state because each "ImageField" handles one field.
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  const getFileNameFromUrl = (url: string) => {
    try {
      return url.split("/").pop() || "existing-image";
    } catch {
      return "existing-image";
    }
  };

  // 3) Handler for removing the existing image
  const handleRemove = () => {
    formik.setFieldValue(fieldKey, {
      ...fieldValue,
      remove: true,
      file: null, // clear any new file if we decide to remove the existing
    });
    setPreviewUrl(null);
  };

  // 4) Render the file input + existing preview logic
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      {/* The file input (new upload) */}

      <FormFile
        name={fieldKey}
        required={field.is_required}
        multiple={false}
        existingUrl={
          existingUrl && !remove ? getFileNameFromUrl(existingUrl) : undefined
        }
      />
      {/* If there's an existing image and not removed, show the preview UI */}
      {existingUrl && !remove && (
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <StyledText color={palette.textSecondary} fontSize={14}>
            {t("event_form_fields.existing_image")}:{" "}
            {existingUrl && !remove
              ? getFileNameFromUrl(existingUrl)
              : undefined}
          </StyledText>
          <IconButton
            onClick={() => setPreviewUrl(existingUrl)}
            size="sm"
            className="image-field-button"
            sx={{
              height: "fit-content",
              p: "4px",
              "&.image-field-button": {
                paddingBottom: "4px !important",
              },
            }}
          >
            <Visibility
              sx={{
                fontSize: 24,
                color: palette.black,
              }}
            />
          </IconButton>
          <IconButton
            onClick={handleRemove}
            size="sm"
            className="image-field-button"
            sx={{
              height: "fit-content",
              p: "4px",
              "&.image-field-button": {
                paddingBottom: "4px !important",
              },
            }}
          >
            <DeleteIcon
              sx={{
                fontSize: 24,
                color: palette.textSecondary,
              }}
            />
          </IconButton>
        </Stack>
      )}

      {/* The ExistingFilePreview modal—only shows if previewUrl is set */}
      <ExistingFilePreview
        previewUrl={previewUrl ?? ""}
        setPreviewUrl={setPreviewUrl}
        onRemove={handleRemove}
      />
    </Box>
  );
};

export default ImageField;
