export const enMealTags = {
  vegetarian: "Vegetarian",
  vegan: "Vegan",
  gluten_free: "Gluten-free",
  dairy_free: "Dairy-free",
  spicy: "Spicy",
  contains_nuts: "Contains nuts",
  low_carb: "Low-carb",
  high_protein: "High-protein",
  paleo: "Paleo",
  keto: "Keto",
  organic: "Organic",
  non_gmo: "Non-GMO",
  sugar_free: "Sugar-free",
  low_sodium: "Low-sodium",
  halal: "Halal",
  kosher: "Kosher",
  pescatarian: "Pescatarian",
  whole30: "Whole30",
  low_fat: "Low-fat",
  soy_free: "Soy-free",
  egg_free: "Egg-free",
  shellfish_free: "Shellfish-free",
  peanut_free: "Peanut-free",
  tree_nut_free: "Tree nut-free",
  lactose_free: "Lactose-free",
  no_added_sugar: "No added sugar",
  low_calorie: "Low-calorie",
  high_fiber: "High-fiber",
  raw: "Raw",
  fermented: "Fermented",
  probiotic: "Probiotic",
  antioxidant_rich: "Antioxidant-rich",
  heart_healthy: "Heart-healthy",
  diabetic_friendly: "Diabetic-friendly",
  kid_friendly: "Kid-friendly",
  elderly_friendly: "Elderly-friendly",
  athlete_friendly: "Athlete-friendly",
  pregnancy_safe: "Pregnancy-safe",
  post_workout: "Post-workout",
  pre_workout: "Pre-workout",
  brain_boosting: "Brain-boosting",
  immune_boosting: "Immune-boosting",
  detox: "Detox",
  anti_inflammatory: "Anti-inflammatory",
  bone_health: "Bone-health",
  energy_boosting: "Energy-boosting",
  mood_enhancing: "Mood-enhancing",
  stress_relief: "Stress-relief",
  hydrating: "Hydrating",
  weight_loss: "Weight-loss",
  weight_gain: "Weight-gain",
  balanced: "Balanced",
  comfort_food: "Comfort food",
  gourmet: "Gourmet",
  quick_easy: "Quick & easy",
  budget_friendly: "Budget-friendly",
  seasonal: "Seasonal",
  holiday: "Holiday",
  bbq: "BBQ",
  grilled: "Grilled",
  baked: "Baked",
  fried: "Fried",
  steamed: "Steamed",
  slow_cooked: "Slow-cooked",
  instant_pot: "Instant Pot",
  air_fried: "Air-fried",
  sous_vide: "Sous-vide",
  stir_fried: "Stir-fried",
  roasted: "Roasted",
  smoked: "Smoked",
  marinated: "Marinated",
  pickled: "Pickled",
  mild: "Mild",
  savory: "Savory",
  sweet: "Sweet",
  sour: "Sour",
  bitter: "Bitter",
  umami: "Umami",
  fusion: "Fusion",
  traditional: "Traditional",
  street_food: "Street food",
};

export const seMealTags = {
  vegetarian: "Vegetarisk",
  vegan: "Vegansk",
  gluten_free: "Glutenfri",
  dairy_free: "Mjölkfri",
  spicy: "Stark",
  contains_nuts: "Innehåller nötter",
  low_carb: "Lågkolhydrat",
  high_protein: "Proteinrik",
  paleo: "Paleo",
  keto: "Keto",
  organic: "Ekologisk",
  non_gmo: "GMO-fri",
  sugar_free: "Sockerfri",
  low_sodium: "Låg salthalt",
  halal: "Halal",
  kosher: "Kosher",
  pescatarian: "Pescetarian",
  whole30: "Whole30",
  low_fat: "Fettsnål",
  soy_free: "Sojafri",
  egg_free: "Äggfri",
  shellfish_free: "Skaldjursfri",
  peanut_free: "Jordnötsfri",
  tree_nut_free: "Trädnötsfri",
  lactose_free: "Laktosfri",
  no_added_sugar: "Utan tillsatt socker",
  low_calorie: "Lågkalori",
  high_fiber: "Fiberrik",
  raw: "Rå",
  fermented: "Fermenterad",
  probiotic: "Probiotisk",
  antioxidant_rich: "Antioxidantrik",
  heart_healthy: "Hjärtvänlig",
  diabetic_friendly: "Diabetesvänlig",
  kid_friendly: "Barnvänlig",
  elderly_friendly: "Äldrevänlig",
  athlete_friendly: "Idrottarvänlig",
  pregnancy_safe: "Graviditetssäker",
  post_workout: "Efter träning",
  pre_workout: "Före träning",
  brain_boosting: "Hjärnstärkande",
  immune_boosting: "Immunstärkande",
  detox: "Detox",
  anti_inflammatory: "Antiinflammatorisk",
  bone_health: "Benhälsa",
  energy_boosting: "Energigivande",
  mood_enhancing: "Humörhöjande",
  stress_relief: "Stresslindrande",
  hydrating: "Vätskegivande",
  weight_loss: "Viktminskande",
  weight_gain: "Viktökande",
  balanced: "Balanserad",
  comfort_food: "Husmanskost",
  gourmet: "Gourmet",
  quick_easy: "Snabb & enkel",
  budget_friendly: "Budgetvänlig",
  seasonal: "Säsongsbetonad",
  holiday: "Högtid",
  bbq: "Grillad",
  grilled: "Grillad",
  baked: "Bakad",
  fried: "Friterad",
  steamed: "Ångkokt",
  slow_cooked: "Långkokt",
  instant_pot: "Tryckkokare",
  air_fried: "Luftfriterad",
  sous_vide: "Sous-vide",
  stir_fried: "Wokad",
  roasted: "Rostad",
  smoked: "Rökt",
  marinated: "Marinerad",
  pickled: "Inlagd",
  mild: "Mild",
  savory: "Smakrik",
  sweet: "Söt",
  sour: "Sur",
  bitter: "Bitter",
  umami: "Umami",
  fusion: "Fusion",
  traditional: "Traditionell",
  street_food: "Gatumat",
};
