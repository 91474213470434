import { Box, Card, Divider, Grid, IconButton } from "@mui/joy";
import { motion, AnimatePresence } from "framer-motion";
import usePalette from "../../../theme/use_palette";
import StyledText from "../../text/styled_text";
import {
  IAIEventData,
  IEventPostReq,
  ITicketReleaseMethodType,
  ITicketReleasePostReq,
  ITicketTypePostReq,
} from "../../../types";
import { useTranslation } from "react-i18next";
import {
  AccessTime,
  CalendarMonth,
  Category,
  Description,
  Language,
  LocationOn,
  Person,
  Public,
  Restaurant,
  SellOutlined,
  Timer,
  Title as TitleIcon,
  ConfirmationNumber,
  Notifications,
  Payment,
  Lock,
  LockOpen,
  EventSeat,
} from "@mui/icons-material";

export const mapTicketReleaseMethod = (id: number) => {
  switch (id) {
    case 1:
      return ITicketReleaseMethodType.FCFS_LOTTERY;
    case 2:
      return ITicketReleaseMethodType.RESERVED_TICKET_RELEASE;
    case 3:
      return ITicketReleaseMethodType.FCFS;
    case 4:
      return ITicketReleaseMethodType.SELECTIVE;
    case 5:
      return ITicketReleaseMethodType.PAY_DIRECT;
    default:
      return "Unknown";
  }
};

// Create reusable field component
const Field: React.FC<{
  label: string;
  value: string | number | boolean | Date | undefined | null;
  icon?: React.ReactNode;
  sx?: any;
  gridColumn?: string;
}> = ({ label, value, icon, sx = {}, gridColumn }) => {
  const palette = usePalette();
  const { t } = useTranslation();

  if (value === undefined || value === null) return null;

  return (
    <Box
      sx={{
        mb: 0,
        display: "flex",
        alignItems: "flex-start",
        gap: 1,
        gridColumn,
        ...sx,
      }}
    >
      {icon && <Box sx={{ color: palette.primaryMain, mt: 0.5 }}>{icon}</Box>}
      <AnimatePresence mode="wait">
        <motion.div
          key={`${label}-${value}`}
          initial={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
          animate={{
            backgroundColor: [
              "rgba(0, 0, 0, 0)",
              "rgba(25, 118, 210, 0.12)",
              "rgba(0, 0, 0, 0)",
            ],
          }}
          transition={{
            duration: 1,
            times: [0, 0.5, 1],
          }}
          style={{
            borderRadius: "4px",
            padding: "4px 8px",
            width: "100%",
          }}
        >
          <Box>
            <StyledText
              level="body-md"
              color={palette.charcoal}
              fontWeight={700}
            >
              {label}
            </StyledText>
            <StyledText level="body-md" color={palette.charcoal}>
              {value instanceof Date
                ? value.toLocaleString()
                : typeof value === "boolean"
                ? t(
                    value
                      ? "manager.ai_create.fields.yes"
                      : "manager.ai_create.fields.no"
                  )
                : value}
            </StyledText>
          </Box>
        </motion.div>
      </AnimatePresence>
    </Box>
  );
};

const TicketTypeDisplay: React.FC<{
  ticketType: Partial<ITicketTypePostReq>;
}> = ({ ticketType }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        borderRadius: 1,
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gap: 2,
      }}
    >
      <Field
        label={t("manager.ai_create.fields.name")}
        value={ticketType.name}
        icon={<TitleIcon />}
      />
      {ticketType.price !== undefined && (
        <Field
          label={t("manager.ai_create.fields.price")}
          value={`${ticketType.price} SEK`}
          icon={<Payment />}
        />
      )}
      <Field
        gridColumn="1 / -1"
        label={t("manager.ai_create.fields.description")}
        value={ticketType.description}
        icon={<Description />}
      />
    </Box>
  );
};

const TicketReleaseDisplay: React.FC<{
  release: Partial<ITicketReleasePostReq>;
}> = ({ release }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        mt: 0,
        borderRadius: 1,
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gap: 0,
      }}
    >
      <Field
        label={t("manager.ai_create.fields.name")}
        value={release.name}
        icon={<TitleIcon />}
      />
      <Field
        label={t("manager.ai_create.fields.notification_method")}
        value={release.notification_method}
        icon={<Notifications />}
      />
      <Field
        gridColumn="1 / -1"
        label={t("manager.ai_create.fields.description")}
        value={release.description}
        icon={<Description />}
      />
      {release.open && (
        <Field
          label={t("manager.ai_create.fields.open")}
          value={new Date(release.open)}
          icon={<Timer />}
        />
      )}
      {release.close && (
        <Field
          label={t("manager.ai_create.fields.close")}
          value={new Date(release.close)}
          icon={<Timer />}
        />
      )}
      {release.ticket_release_method_id && (
        <Field
          gridColumn="1 / -1"
          label={t("manager.ai_create.fields.ticket_release_method")}
          value={mapTicketReleaseMethod(release.ticket_release_method_id)}
          icon={<SellOutlined />}
        />
      )}
      {release.method_description && (
        <Field
          gridColumn="1 / -1"
          label={t("manager.ai_create.fields.method_description")}
          value={release.method_description}
          icon={<Description />}
        />
      )}
      {release.open_window_duration && (
        <Field
          label={t("manager.ai_create.fields.open_window_duration")}
          value={new Date(release.open_window_duration)}
          icon={<AccessTime />}
        />
      )}
      {release.max_tickets_per_user && (
        <Field
          label={t("manager.ai_create.fields.max_tickets_per_user")}
          value={release.max_tickets_per_user}
          icon={<Person />}
        />
      )}
      <Field
        gridColumn="1 / -1"
        label={t("manager.ai_create.fields.cancellation_policy")}
        value={release.cancellation_policy}
        icon={<Description />}
      />
      {release.is_reserved && release.promo_code && (
        <Field
          label={t("manager.ai_create.fields.promo_code")}
          value={release.promo_code}
          icon={<Lock />}
        />
      )}
      {release.tickets_available && (
        <Field
          label={t("manager.ai_create.fields.tickets_available")}
          value={release.tickets_available}
          icon={<ConfirmationNumber />}
        />
      )}
      {release.payment_deadline && (
        <Field
          label={t("manager.ai_create.fields.payment_deadline")}
          value={new Date(release.payment_deadline)}
          icon={<Timer />}
        />
      )}
      {release.reserve_payment_duration && (
        <Field
          label={t("manager.ai_create.fields.reserve_payment_duration")}
          value={release.reserve_payment_duration}
          icon={<AccessTime />}
        />
      )}
      {release.allocation_cut_off && (
        <Field
          label={t("manager.ai_create.fields.allocation_cut_off")}
          value={new Date(release.allocation_cut_off)}
          icon={<Timer />}
        />
      )}
    </Box>
  );
};

const EventDataDisplay: React.FC<{ eventData: Partial<IAIEventData> }> = ({
  eventData,
}) => {
  const palette = usePalette();
  const { t } = useTranslation();

  const renderEventDetails = (event: Partial<IEventPostReq>) => (
    <Box
      sx={{
        borderRadius: 1,
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gap: 2,
      }}
    >
      <Field
        label={t("manager.ai_create.fields.name")}
        value={event.name}
        icon={<TitleIcon />}
      />
      <Field
        label={t("manager.ai_create.fields.category")}
        value={event.category}
        icon={<Category />}
      />
      <Field
        gridColumn="1 / -1"
        label={t("manager.ai_create.fields.description")}
        value={event.description}
        icon={<Description />}
      />
      {event.date && (
        <Field
          label={t("manager.ai_create.fields.date")}
          value={new Date(event.date)}
          icon={<CalendarMonth />}
        />
      )}
      {event.end_date && (
        <Field
          label={t("manager.ai_create.fields.end_date")}
          value={new Date(event.end_date)}
          icon={<CalendarMonth />}
        />
      )}
      <Field
        label={t("manager.ai_create.fields.location")}
        value={event.location}
        icon={<LocationOn />}
      />
      <Field
        label={t("manager.ai_create.fields.organization_id")}
        value={event.organization_id}
        icon={<Person />}
      />
      <Field
        label={t("manager.ai_create.fields.is_private")}
        value={event.is_private}
        icon={event.is_private ? <Lock /> : <Public />}
      />
      <Field
        label={t("manager.ai_create.fields.collect_food_preferences")}
        value={event.collect_food_preferences}
        icon={<Restaurant />}
      />
      {event.language && (
        <Field
          label={t("manager.ai_create.fields.language")}
          value={event.language.toUpperCase()}
          icon={<Language />}
        />
      )}
    </Box>
  );

  return (
    <Box
      sx={{
        mt: 2,
        p: 3,
        bgcolor: palette.white,
        borderRadius: 8,
        boxShadow: `0 4px 12px ${palette.greyLight}`,
      }}
    >
      {eventData.event && (
        <Card
          variant="outlined"
          sx={{
            mb: 1,
            p: 2,
            bgcolor: palette.white,
            borderRadius: 2,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <CalendarMonth sx={{ color: palette.primaryMain }} />
            <StyledText
              level="body-lg"
              fontWeight={600}
              fontSize={22}
              color={palette.primaryMain}
            >
              {t("manager.ai_create.event_details")}
            </StyledText>
          </Box>
          {renderEventDetails(eventData.event)}
        </Card>
      )}

      {eventData.ticket_release && (
        <Card
          variant="outlined"
          sx={{
            mb: 1,
            p: 2,
            bgcolor: palette.white,
            borderRadius: 2,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <SellOutlined sx={{ color: palette.primaryMain }} />
            <StyledText
              level="body-lg"
              fontWeight={600}
              fontSize={22}
              color={palette.primaryMain}
            >
              {t("manager.ai_create.ticket_release")}
            </StyledText>
          </Box>
          <TicketReleaseDisplay release={eventData.ticket_release} />
        </Card>
      )}

      {eventData.ticket_types && eventData.ticket_types.length > 0 && (
        <Card
          variant="outlined"
          sx={{
            p: 2,
            bgcolor: palette.white,
            borderRadius: 2,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <ConfirmationNumber sx={{ color: palette.primaryMain }} />
            <StyledText
              level="body-lg"
              fontWeight={600}
              fontSize={22}
              color={palette.primaryMain}
            >
              {t("manager.ai_create.ticket_types")}
            </StyledText>
          </Box>
          {eventData.ticket_types.map((ticketType, index) => (
            <Box key={index}>
              <TicketTypeDisplay ticketType={ticketType} />
              {index < eventData.ticket_types!.length - 1 && <Divider />}
            </Box>
          ))}
        </Card>
      )}
    </Box>
  );
};

export default EventDataDisplay;
