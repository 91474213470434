import React from "react";

interface Shape {
  rx: number;
  ry: number;
  cx: number;
  cy: number;
  fill: string;
}

interface BlurryBackgroundProps {
  shapes?: Shape[];
  blurStdDeviation?: number;
  opacity?: number;
  style?: React.CSSProperties;
  width?: number | string;
  height?: number | string;
  scale?: number;
  containerWidth?: string;
  containerHeight?: string;
}

const defaultShapes: Shape[] = [
  {
    rx: 103,
    ry: 150,
    cx: 354,
    cy: 345,
    fill: "hsl(37, 99%, 67%)",
  },
  {
    rx: 103,
    ry: 150,
    cx: 494,
    cy: 373,
    fill: "hsl(316, 73%, 52%)",
  },
  {
    rx: 103,
    ry: 150,
    cx: 305,
    cy: 478,
    fill: "hsl(185, 100%, 57%)",
  },
];

const BlurryBackground: React.FC<BlurryBackgroundProps> = ({
  shapes = defaultShapes,
  blurStdDeviation = 72,
  opacity = 1,
  style,
  width = 800,
  height = 800,
  scale = 1,
  containerWidth = "100%",
  containerHeight = "100%",
}) => {
  // Generate unique ID for the filter to avoid conflicts when using multiple instances
  const filterId = React.useId();

  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: containerWidth,
        height: containerHeight,
        overflow: "hidden",
        ...style,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox={`0 0 ${width} ${height}`}
        style={{
          width: `${scale * 100}%`,
          height: `${scale * 100}%`,
          opacity,
          maxWidth: "none",
          maxHeight: "none",
        }}
        preserveAspectRatio="xMidYMid meet"
      >
        <defs>
          <filter
            id={filterId}
            x="-100%"
            y="-100%"
            width="400%"
            height="400%"
            filterUnits="objectBoundingBox"
            primitiveUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feGaussianBlur
              stdDeviation={blurStdDeviation}
              x="0%"
              y="0%"
              width="100%"
              height="100%"
              in="SourceGraphic"
              edgeMode="none"
              result="blur"
            />
          </filter>
        </defs>
        <g filter={`url(#${filterId})`}>
          {shapes.map((shape, index) => (
            <ellipse
              key={index}
              rx={shape.rx}
              ry={shape.ry}
              cx={shape.cx}
              cy={shape.cy}
              fill={shape.fill}
            />
          ))}
        </g>
      </svg>
    </div>
  );
};

export default BlurryBackground;
