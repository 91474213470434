import { PayloadAction } from "@reduxjs/toolkit";
import { IEvent, IEventFinancialDetailsPostReq } from "../../types";
import { call, takeLatest } from "redux-saga/effects";
import { ApiResponse, putApi } from "../../utils/api/api";
import { toast } from "react-toastify";
import { updateEventFinancialRequest } from "../features/eventFinanicalSlice";
import ApiRoutes from "../../routes/backend_routes";
import { handleApiError } from "../../utils/error_handlers";

function* eventFinancialSaga(
  action: PayloadAction<{
    eventID: number;
    financialDetails: IEventFinancialDetailsPostReq;
  }>
): Generator<any, void, any> {
  try {
    const { eventID, financialDetails } = action.payload;

    const response: ApiResponse<{
      event: IEvent;
    }> = yield call(
      putApi,
      ApiRoutes.generateRoute(ApiRoutes.MANAGER_EVENT_DETAILS, {
        eventID,
      }),
      financialDetails,
      true,
      true
    );

    if (response.status === "success") {
      toast.success("Event financials updated successfully");
    }
  } catch (error: any) {
    handleApiError(error, "Failed to update event financials", false);
  }
}

function* watchEventFinancialSaga() {
  yield takeLatest(updateEventFinancialRequest.type, eventFinancialSaga);
}

export default watchEventFinancialSaga;
