import { call, put, takeLatest } from "redux-saga/effects";
import axios, { AxiosProgressEvent } from "axios";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  EventCategoryType,
  IEvent,
  IEventForm,
  IEventPostReq,
} from "../../types";
import {
  deleteEventBannerFailure,
  deleteEventBannerRequest,
  deleteEventBannerSuccess,
  getEventFailure,
  getEventRequest,
  getEventSuccess,
  updateEventBannerUploadProgress,
  uploadEventBannerFailure,
  uploadEventBannerRequest,
  uploadEventBannerSuccess,
} from "../features/eventSlice";
import { toast } from "react-toastify";
import {
  deleteEventFailure,
  deleteEventStart,
  deleteEventSuccess,
  editEventFailure,
  editEventRequest,
  editEventSuccess,
} from "../features/editEventSlice";
import { setTimestamp } from "../features/serverTimestampSlice";
import ApiRoutes from "../../routes/backend_routes";
import { ApiResponse, deleteApi, fetchApi, postApi } from "../../utils/api/api";
import { parseISO } from "date-fns";

function* eventSaga(
  action: PayloadAction<{
    id: number;
    secretToken: string;
    countSiteVisit?: boolean;
    promoCodes?: string[];
  }>
): Generator<any, void, any> {
  try {
    const { id, secretToken } = action.payload;
    const queryParams = [];

    if (secretToken !== "") {
      queryParams.push("secret_token=" + secretToken);
    }

    if (!action.payload.countSiteVisit) {
      queryParams.push("dont_count_site_visit=true");
    }

    if (action.payload.promoCodes) {
      action.payload.promoCodes.forEach((promoCode: string) => {
        queryParams.push("promo_codes=" + promoCode);
      });
    }

    const queryString =
      queryParams.length > 0 ? "?" + queryParams.join("&") : "";

    const url =
      process.env.REACT_APP_BACKEND_URL + "/events/" + id + queryString;

    const response: ApiResponse<{
      event: IEvent;
    }> = yield call(fetchApi, url, true, true);

    const time_response: ApiResponse<{
      timestamp: number;
    }> = yield call(
      fetchApi,
      process.env.REACT_APP_BACKEND_URL + "/timestamp",
      true,
      true
    );

    yield put(getEventSuccess(response.data.event));
    yield put(
      setTimestamp(new Date(time_response.data.timestamp * 1000).getTime())
    );
  } catch (error: any) {
    const errorMessage = error.response.data.error || "An error occurred";
    yield put(
      getEventFailure({
        error: errorMessage,
        errorStatusCode: error.response.status,
      })
    );
  }
}

function* editEventSaga(
  action: PayloadAction<{
    id: number;
    event: IEventForm;
  }>
): Generator<any, void, any> {
  try {
    const { event, id } = action.payload;

    const data: IEventPostReq = {
      name: event.name,
      description: event.description,
      location: event.location!.label,
      date: new Date(event.date).toISOString(),
      end_date: event.end_date ? event.end_date : undefined,
      is_private: event.is_private,
      organization_id: event.organization_id,
      collect_food_preferences: event.collect_food_preferences,
      language: event.language,
      category: event.category ?? EventCategoryType.Event,
      display_attendee_count: event.display_attendee_count || false,
      tags: event.tags || [],
    };

    const response = yield call(
      axios.put,
      ApiRoutes.generateRoute(ApiRoutes.MANAGER_EVENT, {
        eventID: id,
      }),
      data,
      {
        withCredentials: true, // This ensures cookies are sent with the request
      }
    );

    if (response.status === 200) {
      toast.success("Event updated successfully!");
      yield put(editEventSuccess(response.data));
    } else {
      const errorMessage = response.data.error || "An error occurred";
      yield put(editEventFailure(errorMessage));
    }
  } catch (error: any) {
    const errorMessage = error.response.data.error || "An error occurred";
    toast.error(errorMessage);
    yield put(editEventFailure(errorMessage));
  }
}

function* deleteEventSaga(
  action: PayloadAction<number>
): Generator<any, void, any> {
  try {
    const response = yield call(
      axios.delete,
      ApiRoutes.generateRoute(ApiRoutes.MANAGER_EVENT, {
        eventID: action.payload,
      }),
      {
        withCredentials: true, // This ensures cookies are sent with the request
      }
    );

    if (response.status === 200) {
      toast.success("Event deleted successfully!");
      yield put(deleteEventSuccess(response.data));
    } else {
      const errorMessage = response.data.error || "An error occurred";
      yield put(deleteEventFailure(errorMessage));
    }
  } catch (error: any) {
    const errorMessage = error.response.data.error || "An error occurred";
    toast.error(errorMessage);
    yield put(deleteEventFailure(errorMessage));
  }
}

function* uploadEventBannerSaga(
  action: PayloadAction<{ eventId: number; file: File }>
): Generator<any, void, any> {
  const { eventId, file } = action.payload;
  try {
    // Check image dimensions
    const dimensions = yield new Promise<{ width: number; height: number }>(
      (resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          const img = new Image();
          img.onload = () => {
            resolve({ width: img.width, height: img.height });
          };
          img.onerror = reject;
          img.src = event.target?.result as string;
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      }
    );

    // Proceed with the upload if dimensions are valid
    const formData = new FormData();
    formData.append("banner", file);

    const response: ApiResponse<{ banner_url: string }> = yield call(
      postApi,
      ApiRoutes.generateRoute(ApiRoutes.MANAGER_EVENT_BANNER, {
        eventID: eventId,
      }),
      formData,
      true,
      true,
      (progressEvent: AxiosProgressEvent) => {
        if (progressEvent.total) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          put(updateEventBannerUploadProgress(percentCompleted));
        }
      }
    );

    if (response.status === "success") {
      yield put(uploadEventBannerSuccess(response.data.banner_url));
      toast.success("Event banner uploaded successfully");
    } else {
      yield put(uploadEventBannerFailure(response.message));
    }
  } catch (error: any) {
    const errorMessage =
      error.response?.data?.error || error.message || "An error occurred";
    toast.error(errorMessage);
    yield put(uploadEventBannerFailure(errorMessage));
  }
}

// Delete banner using deleteApi
function* deleteEventBannerSaga(
  action: PayloadAction<number>
): Generator<any, void, any> {
  try {
    const response: ApiResponse<void> = yield call(
      deleteApi,
      ApiRoutes.generateRoute(ApiRoutes.MANAGER_EVENT_BANNER, {
        eventID: action.payload,
      }),
      {},
      true,
      true
    );

    if (response.status === "success") {
      yield put(deleteEventBannerSuccess());
      toast.success("Event banner deleted successfully");
    } else {
      yield put(deleteEventBannerFailure(response.message));
    }
  } catch (error: any) {
    const errorMessage =
      error.response?.data?.error || error.message || "An error occurred";
    toast.error(errorMessage);
    yield put(deleteEventBannerFailure(errorMessage));
  }
}

function* watchEventSaga() {
  yield takeLatest(deleteEventStart.type, deleteEventSaga);
  yield takeLatest(editEventRequest.type, editEventSaga);
  yield takeLatest(getEventRequest.type, eventSaga);
  yield takeLatest(uploadEventBannerRequest.type, uploadEventBannerSaga);
  yield takeLatest(deleteEventBannerRequest.type, deleteEventBannerSaga);
}

export default watchEventSaga;
