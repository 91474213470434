import React, { useEffect } from "react";
import { motion, useAnimation, Variants } from "framer-motion";
import { useInView } from "react-intersection-observer";

interface AnimatedComponentProps {
  children: React.ReactNode;
  variants: Variants;
  threshold?: number;
  triggerOnce?: boolean;
  style?: React.CSSProperties;
}

const AnimatedComponent: React.FC<AnimatedComponentProps> = ({
  children,
  variants,
  threshold = 0.1,
  triggerOnce = true,
  style,
}) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce,
    threshold,
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={variants}
      style={style}
    >
      {children}
    </motion.div>
  );
};

export default AnimatedComponent;
