import React, { useState, useRef } from "react";
import { generateRoute, ROUTES } from "../../routes/def";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { userHasRole } from "../../utils/roles/has_role";
import { RoleType } from "../../types";
import { isEventManager } from "../../utils/roles/manager";
import StyledText from "../text/styled_text";
import { Box, Link, Stack } from "@mui/joy";
import MenuList from "@mui/joy/MenuList";
import MenuItem from "@mui/joy/MenuItem";
import { Popper } from "@mui/base/Popper";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { styled } from "@mui/joy/styles";
import styles from "./nav.module.css";
import { adjustTransparency, lightenColor } from "../../utils/manager/color";
import usePalette from "../../theme/use_palette";
import { useNavigate } from "react-router-dom";
import MenuItemComponent from "./menu_item";
import { RootState } from "../../store";

const Popup = styled(Popper)({
  zIndex: 1000,
});

const fadeDownKeyframes = `
  @keyframes fadeDown {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const AnimatedMenuList = styled(MenuList)`
  ${fadeDownKeyframes}
  animation: fadeDown 0.3s ease-out;
`;

const StyledMenuItem = styled("div")(({ theme }) => ({
  padding: "2px 8px",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  transition: "all 0.2s ease-out",

  "&:hover": {
    backgroundColor: usePalette().greyLight,
  },
}));

const AnimatedMenuItem = styled(StyledMenuItem)<{ delay: number }>`
  opacity: 0;
  animation: fadeDown 0.3s ease-out forwards;
  animation-delay: ${(props) => props.delay}s;
`;

const CustomStyledText = styled(StyledText)(({ theme }) => ({
  margin: "0 16px",
  letterSpacing: "0.075em",
  transition: "all 0.3s  ease-in-out",
}));

const CustomLink = styled(Link)(({ theme }) => ({
  position: "relative",
  color: usePalette().black,
  textDecoration: "none !important",
  fontWeight: 600,
  transition: "all 0.3s ease-in-out",

  "&, &:hover": {
    textDecoration: "none !important",
  },

  "&::after": {
    content: '""',
    position: "absolute",
    bottom: 0,
    left: "50%",
    width: "100%",
    height: "2px",
    background: "currentColor",
    transform: "translateX(-50%) scaleX(0)",
    transformOrigin: "center",
    transition: "transform 0.3s ease-out",
  },

  "&:hover::after": {
    transform: "translateX(-50%) scaleX(1)",
  },
}));

interface NavLinkTextProps {
  href?: string;
  onClick?: () => void;
  children: React.ReactNode;
  isDropdown?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

const NavLinkText: React.FC<NavLinkTextProps> = ({
  href,
  onClick,
  children,
  isDropdown = false,
  onMouseEnter,
  onMouseLeave,
}) => (
  <CustomStyledText
    level="body-sm"
    color={"inherit"}
    fontSize={20}
    fontWeight={500}
    style={{
      margin: "0 16px",
      letterSpacing: "0.025em",
      textDecoration: "none",
    }}
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    <CustomLink href={href} className={styles.link}>
      {children}
    </CustomLink>
  </CustomStyledText>
);

interface NavLinksProps {
  textColor: string;
  onClick?: () => void;
}

const NavLinks: React.FC<NavLinksProps> = ({ textColor, onClick }) => {
  const { t } = useTranslation();
  const { user: currentUser } = useSelector((state: RootState) => state.user);
  const { isLoggedIn } = useSelector((state: RootState) => state.auth);
  const [isProductMenuOpen, setIsProductMenuOpen] = useState(false);
  const [isSolutionsMenuOpen, setIsSolutionsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const productButtonRef = useRef<HTMLDivElement>(null);
  const solutionsButtonRef = useRef<HTMLDivElement>(null);
  const palette = usePalette();

  const handleProductClose = () => {
    setIsProductMenuOpen(false);
  };

  const handleSolutionsClose = () => {
    setIsSolutionsMenuOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === "Tab") {
      setIsProductMenuOpen(false);
      setIsSolutionsMenuOpen(false);
    } else if (event.key === "Escape") {
      if (isProductMenuOpen) {
        productButtonRef.current!.focus();
        setIsProductMenuOpen(false);
      }
      if (isSolutionsMenuOpen) {
        solutionsButtonRef.current!.focus();
        setIsSolutionsMenuOpen(false);
      }
    }
  };

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Box ref={productButtonRef}>
        <NavLinkText
          href={generateRoute(ROUTES.PRODUCT, {})}
          onClick={() => navigate(ROUTES.PRODUCT)}
          isDropdown
          onMouseEnter={() => setIsProductMenuOpen(true)}
          onMouseLeave={() => setIsProductMenuOpen(false)}
        >
          {t("navigation.product")}
        </NavLinkText>
        <Popup
          open={isProductMenuOpen}
          anchorEl={productButtonRef.current}
          placement="bottom-start"
          style={{
            zIndex: 1000,
            marginTop: "8px",
          }}
        >
          <Box
            onMouseEnter={() => setIsProductMenuOpen(true)}
            onMouseLeave={() => setIsProductMenuOpen(false)}
          >
            <ClickAwayListener onClickAway={handleProductClose}>
              <AnimatedMenuList
                variant="outlined"
                onKeyDown={handleListKeyDown}
                sx={{ boxShadow: "md", backgroundColor: usePalette().white }}
              >
                {[
                  {
                    text: "product.titles.ticket_distribution",
                    route: generateRoute(
                      ROUTES.PRODUCT_TICKET_DISTRIBUTION,
                      {}
                    ),
                  },
                  {
                    text: "product.titles.event_management",
                    route: generateRoute(ROUTES.PRODUCT_EVENT_MANAGEMENT, {}),
                  },
                  {
                    text: "product.titles.event_pages",
                    route: generateRoute(ROUTES.PRODUCT_EVENT_PAGES, {}),
                  },
                  {
                    text: "product.titles.team_management",
                    route: generateRoute(ROUTES.PRODUCT_TEAM_MANAGEMENT, {}),
                  },
                  {
                    text: "product.titles.beta_program",
                    route: generateRoute(ROUTES.PRODUCT_BETA_PROGRAM, {}),
                  },
                  {
                    text: "product.titles.manager_onboarding",
                    route: generateRoute(ROUTES.PRODUCT_MANAGER_ONBOARDING, {}),
                  },
                  {
                    text: "product.titles.event_forms",
                    route: generateRoute(ROUTES.PRODUCT_EVENT_FORMS, {}),
                  },
                  {
                    text: "product.titles.data_security",
                    route: generateRoute(ROUTES.PRODUCT_DATA_SECURITY, {}),
                  },
                  {
                    text: "product.titles.payments",
                    route: generateRoute(ROUTES.PRODUCT_PAYMENTS, {}),
                  },
                ].map((item, index) => (
                  <AnimatedMenuItem
                    key={item.text}
                    delay={0.1 + index * 0.05}
                    onClick={() => {
                      navigate(item.route);
                      handleProductClose();
                    }}
                  >
                    <MenuItemComponent
                      textColor={palette.charcoal}
                      text={item.text}
                      route={item.route}
                      handleClose={handleProductClose}
                    />
                  </AnimatedMenuItem>
                ))}
              </AnimatedMenuList>
            </ClickAwayListener>
          </Box>
        </Popup>
      </Box>

      <Box ref={solutionsButtonRef}>
        <NavLinkText
          href={generateRoute(ROUTES.SOLUTIONS, {})}
          onClick={() => navigate(ROUTES.SOLUTIONS)}
          isDropdown
          onMouseEnter={() => setIsSolutionsMenuOpen(true)}
          onMouseLeave={() => setIsSolutionsMenuOpen(false)}
        >
          {t("navigation.solutions")}
        </NavLinkText>
        <Popup
          open={isSolutionsMenuOpen}
          anchorEl={solutionsButtonRef.current}
          placement="bottom-start"
          style={{
            zIndex: 1000,
            marginTop: "8px",
          }}
        >
          <Box
            onMouseEnter={() => setIsSolutionsMenuOpen(true)}
            onMouseLeave={() => setIsSolutionsMenuOpen(false)}
          >
            <ClickAwayListener onClickAway={handleSolutionsClose}>
              <AnimatedMenuList
                variant="outlined"
                onKeyDown={handleListKeyDown}
                sx={{ boxShadow: "md", backgroundColor: usePalette().white }}
              >
                {[
                  {
                    text: "solutions.titles.easy_event_setup",
                    route: generateRoute(ROUTES.SOLUTIONS_EASY_EVENT_SETUP, {}),
                  },
                  {
                    text: "solutions.titles.in_person_events",
                    route: generateRoute(ROUTES.SOLUTIONS_IN_PERSON_EVENTS, {}),
                  },
                  {
                    text: "solutions.titles.university_events",
                    route: generateRoute(
                      ROUTES.SOLUTIONS_UNIVERSITY_EVENTS,
                      {}
                    ),
                  },
                  {
                    text: "solutions.titles.complex_teams",
                    route: generateRoute(ROUTES.SOLUTIONS_COMPLEX_TEAMS, {}),
                  },
                ].map((item, index) => (
                  <AnimatedMenuItem
                    key={item.text}
                    delay={0.1 + index * 0.05}
                    onClick={() => {
                      navigate(item.route);
                      handleSolutionsClose();
                    }}
                  >
                    <MenuItemComponent
                      textColor={palette.charcoal}
                      text={item.text}
                      route={item.route}
                      handleClose={handleSolutionsClose}
                    />
                  </AnimatedMenuItem>
                ))}
              </AnimatedMenuList>
            </ClickAwayListener>
          </Box>
        </Popup>
      </Box>

      <Box>
        <NavLinkText href={generateRoute(ROUTES.PRICING, {})} onClick={onClick}>
          {t("navigation.pricing")}
        </NavLinkText>
      </Box>

      <Box>
        <NavLinkText
          href={
            currentUser
              ? generateRoute(
                  isEventManager(currentUser)
                    ? ROUTES.MANAGER_DASHBOARD
                    : ROUTES.BECOME_A_MANAGER,
                  {}
                )
              : ROUTES.MANAGER_DASHBOARD
          }
          onClick={onClick}
        >
          {t("navigation.manager")}
        </NavLinkText>
      </Box>

      {currentUser && userHasRole(currentUser, RoleType.SUPER_ADMIN) && (
        <Box>
          <NavLinkText
            href={generateRoute(ROUTES.EVENTS_ALL, {})}
            onClick={onClick}
          >
            {t("navigation.events")}
          </NavLinkText>
        </Box>
      )}
    </Stack>
  );
};

export default NavLinks;
