import { ShoppingCartItem } from "../redux/features/ticketOrderSlice";

// Update to include ticket and addon handling
export const numberOfTicketRequestInBasket = (
  items: ShoppingCartItem[]
): { [key: number]: number } => {
  let counts: { [key: number]: number } = {};

  items.forEach((item) => {
    // If the ticket ID already exists in the counts object, increment its count
    if (counts[item.ticket.id]) {
      counts[item.ticket.id]++;
    } else {
      // If it doesn't exist, initialize its count to 1
      counts[item.ticket.id] = 1;
    }
  });

  return counts;
};

export const numberOfTotalTicketRequestInBasket = (
  items: ShoppingCartItem[],
  ticketReleaseId: number
): number => {
  let total = 0;

  items.forEach((item) => {
    // If the ticket is part of the given release, increment the total count
    if (item.ticket.ticket_release_id === ticketReleaseId) {
      total++;
    }
  });

  return total;
};

export const getTicketFee = (feeType: string, currency: string = "SEK") => {
  switch (feeType) {
    case "TSSR_STANDARD":
      return `3.5% + 3 ${currency}`;
    case "TSSR_NON_PROFIT":
      return `3% + 1 ${currency}`;
    default:
      return `0 ${currency}`;
  }
};

export const calculateTicketFee = (desiredPrice: number, feeType: string) => {
  let price = desiredPrice;
  switch (feeType) {
    case "TSSR_STANDARD":
      price = (desiredPrice + 3) / (1 - 0.035) - desiredPrice;
      break;
    case "TSSR_NON_PROFIT":
      price = (desiredPrice + 1) / (1 - 0.03) - desiredPrice;
      break;
    default:
      return 0;
  }
  return Math.round(price * 100) / 100;
};

export const calculateTotalPrice = (
  price: number,
  feeType: string,
  vat: number
) => {
  return price + calculateTicketFee(price, feeType) + price * vat;
};
