import { call, put, takeLatest } from "redux-saga/effects";

import {
  fetchCustomizationFailure,
  fetchCustomizationStart,
  fetchCustomizationSuccess,
  setOrganization,
  updateCustomizationFailure,
  updateCustomizationStart,
  updateCustomizationSuccess,
} from "../../../features/manager/organization/organizationStoreCustomization";
import {
  ApiResponse,
  fetchApi,
  putApiMultipart,
} from "../../../../utils/api/api";
import {
  IOrganization,
  IOrganizationStoreCustomization,
} from "../../../../types";
import ApiRoutes from "../../../../routes/backend_routes";
import { PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

// Worker Saga: Fetch Store Customization
function* fetchCustomizationSaga(
  action: PayloadAction<{
    organizationID: number;
  }>
): Generator<any, void, any> {
  try {
    const { organizationID } = action.payload;
    const url = ApiRoutes.generateRoute(
      ApiRoutes.ORGANIZATION_STORE_CUSTOMIZATION,
      {
        organizationID: organizationID,
      }
    );
    const response: ApiResponse<{
      organization: IOrganization;
      store_customization: IOrganizationStoreCustomization;
    }> = yield call(fetchApi, url, true, true);
    yield put(fetchCustomizationSuccess(response.data.store_customization));
    yield put(setOrganization(response.data.organization));
  } catch (error: any) {
    const errorMessage = error.response?.data?.error || error.message;
    toast.error(errorMessage);
    yield put(fetchCustomizationFailure(error.message));
  }
}

// Worker Saga: Update Store Customization
function* updateCustomizationSaga(
  action: PayloadAction<{
    organizationID: number;
    data: IOrganizationStoreCustomization;
  }>
): Generator<any, void, any> {
  try {
    const { organizationID, data } = action.payload;

    const url = ApiRoutes.generateRoute(
      ApiRoutes.ORGANIZATION_STORE_CUSTOMIZATION,
      {
        organizationID: organizationID,
      }
    );

    // Create FormData and append all fields
    const formData = new FormData();
    formData.append("background_color", data.background_color);
    formData.append("brand_color", data.brand_color);
    formData.append("accent_color", data.accent_color);
    formData.append("rect_shape", data.rect_shape);
    formData.append("font_type", data.font_type);

    // Append files if they exist
    if (data.logo_file) {
      formData.append("logo_file", data.logo_file);
    }
    if (data.icon_file) {
      formData.append("icon_file", data.icon_file);
    }

    const response: ApiResponse<{
      store_customization: IOrganizationStoreCustomization;
    }> = yield call(putApiMultipart, url, formData, true, true);

    toast.success("Customization updated successfully");

    yield put(updateCustomizationSuccess(response.data.store_customization));
  } catch (error: any) {
    const errorMessage = error.response?.data?.error || error.message;
    toast.error(errorMessage);
    yield put(updateCustomizationFailure(errorMessage));
  }
}

// Watcher Saga: Watches for dispatched actions
function* organizationCustomizationSaga() {
  yield takeLatest(fetchCustomizationStart.type, fetchCustomizationSaga);
  yield takeLatest(updateCustomizationStart.type, updateCustomizationSaga);
}

export default organizationCustomizationSaga;
