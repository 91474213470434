import React, { useEffect, useState } from "react";
import { Box, Chip, Divider, Link, Stack, IconButton } from "@mui/joy";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { useSwipeable } from "react-swipeable";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import TesseraWrapper from "../../../components/wrappers/page_wrapper";
import FoodPreferences from "../../../components/food_preferences";
import StyledText from "../../../components/text/styled_text";
import LoadingOverlay from "../../../components/Loading";
import Payment from "../../../components/tickets/payment";
import StyledButton from "../../../components/buttons/styled_button";
import TicketQRCode from "../../../components/events/tickets/qr_code";
import ShowTicketOrdersTickets from "../../../components/ticket_orders/ticket_view";

import { AppDispatch, RootState } from "../../../store";
import { getGuestCustomerRequest } from "../../../redux/features/guestCustomerSlice";
import { cancelMyTicketStart } from "../../../redux/features/myTicketsSlice";
import { cancelTicketOrderRequest } from "../../../redux/features/myTicketOrderSlice";

import usePalette from "../../../theme/use_palette";
import FourOFour404 from "../../errors/404";
import AttendeeDetailsForm from "../../../components/events/ticket_release/ticket_request/attendee_details_form";
import { AttendeeFormValues } from "../../../types";
import EditTicketDetailsModal from "../../../components/tickets/edit_ticket_details_modal";
import EditIcon from "@mui/icons-material/Edit";
import { updateAttendees } from "../../../redux/sagas/axios_calls/ticket/attendee_service";
import { text } from "stream/consumers";

const GuestTicketRequestPage: React.FC = () => {
  const { refID, ugkthid } = useParams();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [payDirect, setPayDirect] = useState<boolean>(false);
  const [currentTicketIndex, setCurrentTicketIndex] = useState<number>(0);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);

  const { guestCustomer, loading } = useSelector(
    (state: RootState) => state.guestCustomer
  );

  const { deleteSucess: ticketOrderDeleteSuccess } = useSelector(
    (state: RootState) => state.myTicketOrders
  );

  const { deleteSucess: ticketDeleteSuccess } = useSelector(
    (state: RootState) => state.myTickets
  );

  const ticket_order = guestCustomer?.ticket_orders![0];

  const handlers = useSwipeable({
    onSwipedLeft: () => handleNextTicket(),
    onSwipedRight: () => handlePreviousTicket(),
    trackMouse: true,
  });

  const palette = usePalette();

  useEffect(() => {
    if (!refID || !ugkthid) {
      toast.error("Missing required parameters");
      return;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const requestToken = urlParams.get("request_token");

    if (!requestToken) {
      toast.error("Missing required parameters");
      return;
    }

    dispatch(getGuestCustomerRequest({ ugkthid, request_token: requestToken }));

    // Get the pay_direct query parameter
    const payDirectParam = urlParams.get("pay_direct");
    setPayDirect(payDirectParam === "true");
  }, [refID, ugkthid, dispatch]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const redirect_status = searchParams.get("redirect_status");

    if (redirect_status === "succeeded") {
      toast.success("Payment successful");
      searchParams.delete("redirect_status");
      navigate(`${window.location.pathname}?${searchParams.toString()}`, {
        replace: true,
      });
    }
  }, [navigate]);

  useEffect(() => {
    if (ticketOrderDeleteSuccess) {
      toast.info("Ticket order cancelled!");
      setTimeout(() => {
        navigate("/");
      }, 1000);
    }
  }, [navigate, ticketOrderDeleteSuccess]);

  useEffect(() => {
    if (ticketDeleteSuccess) {
      toast.info("Ticket cancelled successfully");
      setTimeout(() => {
        navigate("/");
      }, 1000);
    }
  }, [navigate, ticketDeleteSuccess]);

  const refetch = () => {
    if (!ugkthid) {
      return;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const requestToken = urlParams.get("request_token");

    if (!requestToken) {
      toast.error("Missing required parameters");
      return;
    }

    dispatch(getGuestCustomerRequest({ ugkthid, request_token: requestToken }));
  };

  const handleNextTicket = () => {
    if (currentTicketIndex < (ticket_order?.tickets.length ?? 1) - 1) {
      setCurrentTicketIndex(currentTicketIndex + 1);
    }
  };

  const handlePreviousTicket = () => {
    if (currentTicketIndex > 0) {
      setCurrentTicketIndex(currentTicketIndex - 1);
    }
  };

  const cancelTicketOrder = () => {
    dispatch(
      cancelTicketOrderRequest({
        ticket_order: ticket_order!,
        isGuestCustomer: true,
        guestCustomer,
      })
    );
  };

  const cancelTicket = (ticketID: number) => {
    const ticket = ticket_order!.tickets.find((t) => t.id === ticketID);
    if (ticket) {
      dispatch(
        cancelMyTicketStart({
          ticket: ticket,
          isGuestCustomer: true,
          guestCustomer: guestCustomer,
        })
      );
    }
  };

  if (loading || !ticket_order) {
    return <LoadingOverlay />;
  }

  if (!ticket_order) {
    return <FourOFour404 />;
  }

  const formFields = ticket_order.ticket_release?.event?.form_fields || [];

  return (
    <TesseraWrapper
      navOptions={{
        isEventPage: true,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          mt: 2,
        }}
      >
        <Box
          sx={{
            mb: 1,
          }}
        >
          <StyledText
            level="body-sm"
            color={palette.black}
            fontSize={14}
            fontWeight={500}
            sx={{ mt: 2, textAlign: "center" }}
          >
            {t("profile.your_tickets.tickets_to")}
          </StyledText>
          <StyledText
            level="h1"
            color={palette.black}
            fontSize={24}
            sx={{ textAlign: "center" }}
          >
            {ticket_order.ticket_release?.event?.name}
          </StyledText>
        </Box>

        {ticket_order.is_handled && !ticket_order?.is_paid && (
          <Payment
            ticketOrder={ticket_order}
            isGuestCustomer={true}
            guestCustomer={guestCustomer}
            payDirect={payDirect}
          />
        )}

        {ticket_order.is_paid && (
          <>
            <Chip
              sx={{
                backgroundColor: palette.successLight,
                mb: 1,
              }}
            >
              <StyledText
                color={palette.black}
                level="body-sm"
                fontSize={16}
                fontWeight={600}
                sx={{ p: 1 }}
              >
                {ticket_order.subtotal_amount === 0
                  ? t("tickets.is_registered")
                  : t("tickets.has_paid")}
              </StyledText>
            </Chip>
            <StyledText
              level="body-md"
              color={palette.charcoalTransparent}
              fontSize={14}
              fontWeight={500}
              sx={{ mb: 1, mx: 2, textAlign: "center" }}
            >
              {t("tickets.request_refund")}
            </StyledText>
          </>
        )}

        {!ticket_order?.is_handled && (
          <StyledButton
            size="md"
            onClick={cancelTicketOrder}
            bgColor={palette.errorMain}
          >
            Cancel
          </StyledButton>
        )}
        {ticket_order.is_paid && (
          <Box
            mt={4}
            display="flex"
            alignItems="center"
            {...handlers}
            sx={{ position: "relative", width: "fit-content" }}
          >
            <IconButton
              onClick={handlePreviousTicket}
              disabled={currentTicketIndex === 0}
            >
              <ArrowBackIosNewIcon />
            </IconButton>
            <Box flexGrow={1} display="flex" justifyContent="center">
              <TicketQRCode
                ticket={ticket_order!.tickets[currentTicketIndex]}
              />
            </Box>
            <IconButton
              onClick={handleNextTicket}
              disabled={currentTicketIndex === ticket_order!.tickets.length - 1}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>
        )}

        <Divider sx={{ my: 2 }} />

        <ShowTicketOrdersTickets ticketOrder={ticket_order} />

        <Divider sx={{ my: 2 }} />

        <EditTicketDetailsModal
          open={editModalOpen}
          onClose={() => setEditModalOpen(false)}
          ticketOrder={ticket_order}
          formFields={formFields}
          onRefetch={refetch}
          guestCustomer={guestCustomer}
          closeOnSubmit={true}
        />
        <Box
          mt={2}
          mb={2}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <StyledButton
            bgColor={palette.black}
            color={palette.white}
            size="sm"
            onClick={() => setEditModalOpen(true)}
            startDecorator={<EditIcon />}
          >
            {t("tickets.edit_details")}
          </StyledButton>
        </Box>
      </Box>
    </TesseraWrapper>
  );
};

export default GuestTicketRequestPage;
