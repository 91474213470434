import React, { useState } from "react";
import {
  Box,
  IconButton,
  Stack,
  Menu,
  MenuItem,
  Dropdown,
  MenuButton,
} from "@mui/joy";
import HomeIcon from "@mui/icons-material/Home";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { RoleType } from "../../types";
import { isEventManager } from "../../utils/roles/manager";
import { ROUTES } from "../../routes/def";
import usePalette from "../../theme/use_palette";
import StyledText from "../text/styled_text";
import { useTranslation } from "react-i18next";
import CategoryIcon from "@mui/icons-material/Category";
import { ThemeToggleButton } from "./theme_toggle_button";
import { adjustTransparency } from "../../utils/manager/color";
import LanguageSelector from "./language_selector";
import MoreVert from "@mui/icons-material/MoreVert";

const MobileBottomNavigation: React.FC = () => {
  const navigate = useNavigate();
  const { user: currentUser } = useSelector((state: RootState) => state.user);
  const { isLoggedIn } = useSelector((state: RootState) => state.auth);
  const palette = usePalette();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleNavigation = (route: string) => {
    navigate(route);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isManager = isLoggedIn && currentUser && isEventManager(currentUser);

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        display: "flex",
        justifyContent: "space-around",
        backgroundColor: "background.surface",
        padding: "8px 0",
        borderTop: "1px solid",
        borderColor: "neutral.outlinedBorder",
      }}
    >
      <Box
        sx={{
          position: "fixed",
          bottom: "64px",
          left: 16,
          borderRadius: "50%",
          bgcolor: adjustTransparency(palette.greyLight, 0.5),
        }}
      >
        <ThemeToggleButton />
      </Box>
      <Box
        sx={{
          position: "fixed",
          bottom: "64px",
          right: 16,
          transform: "scale(0.)",
        }}
      >
        <LanguageSelector />
      </Box>
      <IconButton onClick={() => handleNavigation(ROUTES.MAIN)}>
        <HomeIcon
          sx={{
            color: palette.alwaysBlack,
          }}
        />
      </IconButton>
      <IconButton
        variant="plain"
        onClick={() => handleNavigation(ROUTES.PRICING)}
      >
        <AttachMoneyIcon
          sx={{
            color: palette.alwaysBlack,
          }}
        />
      </IconButton>
      <IconButton
        variant="plain"
        onClick={() =>
          handleNavigation(
            isManager ? ROUTES.MANAGER_DASHBOARD : ROUTES.PRODUCT
          )
        }
      >
        {!isManager ? (
          <CategoryIcon
            sx={{
              color: palette.alwaysBlack,
            }}
          />
        ) : (
          <DashboardIcon
            sx={{
              color: palette.alwaysBlack,
            }}
          />
        )}
      </IconButton>
      {isLoggedIn ? (
        <>
          <IconButton
            variant="plain"
            onClick={() => handleNavigation(ROUTES.PROFILE)}
          >
            <PersonIcon
              sx={{
                color: palette.alwaysBlack,
              }}
            />
          </IconButton>
          <IconButton
            variant="plain"
            onClick={() => handleNavigation(ROUTES.LOGOUT)}
          >
            <LogoutIcon
              sx={{
                color: palette.alwaysBlack,
              }}
            />
          </IconButton>
        </>
      ) : (
        <IconButton
          variant="plain"
          onClick={() => handleNavigation(ROUTES.LOGIN)}
        >
          <StyledText
            level="body-sm"
            fontSize={16}
            color={palette.alwaysBlack}
            fontWeight={700}
          >
            {t("customer.login")}
          </StyledText>
        </IconButton>
      )}
    </Box>
  );
};

export default MobileBottomNavigation;
