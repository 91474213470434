import { Form, Formik } from "formik";
import {
  ICustomerLoginValues,
  ICustomerSignupValues,
  IGuestCustomer,
  IGuestCustomerForm,
  ITicketRelease,
} from "../../../../types";
import * as Yup from "yup";
import { Box, Divider, FormControl, Link, Stack } from "@mui/joy";
import { FormCheckbox, FormInput } from "../../../forms/input_types";
import {
  StyledFormLabel,
  StyledFormLabelWithHelperText,
} from "../../../forms/form_labels";
import { StyledErrorMessage } from "../../../forms/messages";
import StyledButton from "../../../buttons/styled_button";
import usePalette from "../../../../theme/use_palette";
import StyledText from "../../../text/styled_text";
import { useEffect, useState } from "react";
import { AppDispatch, RootState } from "../../../../store";
import { useDispatch } from "react-redux";
import {
  customerLoginRequest,
  customerSignupRequest,
} from "../../../../redux/features/authSlice";
import { useSelector } from "react-redux";
import {
  hasLottery,
  ticketReleaseRequiresAccount,
} from "../../../../utils/manage_event/can_edit_payment_deadline";
import { useTranslation } from "react-i18next";
import CustomerLoginForm from "../../../customer/login_form";
import { createSignupValidationSchema } from "../../../../validation/user/customer_signup_validation";
import CustomerSignupForm from "../../../customer/signup_form";
import OAuthGoogle from "../../../auth/login/oauth/google_login";
import InformationModal from "../../../modal/information";

interface MakeTicketRequestUserDetailsProps {
  accountIsRequired: boolean;
  ticketRelease: ITicketRelease;
  onSignupContinue: (values: ICustomerSignupValues) => void;
  onLoginContinue: () => void;
  forceShowLogin?: boolean;
  forceEmail?: string;
}

const MakeTicketRequestUserDetails: React.FC<
  MakeTicketRequestUserDetailsProps
> = ({
  accountIsRequired,
  ticketRelease,
  onLoginContinue,
  onSignupContinue,
  forceShowLogin = false,
  forceEmail = "",
}) => {
  const dispatch: AppDispatch = useDispatch();
  const palette = usePalette();

  const [hasAccount, setHasAccount] = useState<boolean>(forceShowLogin);

  const onSignup = (values: ICustomerSignupValues) => {
    dispatch(customerSignupRequest(values));

    if (values.is_saved) {
      setHasAccount(true);
    }

    onSignupContinue(values);
  };

  const onLogin = (values: ICustomerLoginValues) => {
    dispatch(customerLoginRequest(values));
  };

  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        maxWidth: "500px",
        margin: "0 auto",
        py: 1,
      }}
    >
      {/* Account Required Message */}
      {accountIsRequired && (
        <StyledText
          level="h4"
          color={palette.primaryMain}
          fontWeight={600}
          fontSize={20}
          sx={{ mb: 3, textAlign: "center" }}
        >
          {t(
            "event.ticket_release.request_process.account_required_description"
          )}
        </StyledText>
      )}

      {hasAccount ? (
        <>
          <CustomerLoginForm onLogin={onLogin} forceEmail={forceEmail} />

          <StyledText
            level="body-sm"
            color={palette.textSecondary}
            fontSize={16}
            sx={{ textAlign: "center", my: 1 }}
          >
            {t("common.or")}
          </StyledText>

          <StyledText
            level="body-md"
            color={palette.black}
            fontSize={16}
            fontWeight={500}
            onClick={() => setHasAccount(false)}
            sx={{ mb: 1, textAlign: "center" }}
          >
            <Link
              href="#"
              sx={{
                textDecoration: "none",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              {t(
                "event.ticket_release.request_process.form.button_continue_as_guest"
              )}
            </Link>
          </StyledText>

          <Box
            sx={{
              mt: 0,
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <StyledText
              level="body-sm"
              color={palette.textSecondary}
              fontSize={14}
              sx={{ textAlign: "center" }}
            >
              {t("customer.oauth")}
            </StyledText>
            <OAuthGoogle />
          </Box>
        </>
      ) : (
        <CustomerSignupForm
          onSignup={onSignup}
          accountIsRequired={accountIsRequired}
        />
      )}

      {!hasAccount && (
        <Box
          sx={{
            mt: 3,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Stack direction="row" alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <StyledText
              level="body-md"
              color={palette.textSecondary}
              fontWeight={400}
              fontSize={15}
              sx={{ textAlign: "center" }}
            >
              {t(
                "event.ticket_release.request_process.already_have_an_account"
              )}{" "}
              <Link
                href="#"
                onClick={() => setHasAccount(true)}
                sx={{
                  color: palette.primaryMain,
                  fontWeight: 500,
                  textDecoration: "none",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                {t("form.button_sign_in")}
              </Link>
            </StyledText>
          </Stack>

          <StyledText
            level="body-sm"
            color={palette.textSecondary}
            fontWeight={400}
            fontSize={14}
            sx={{ mb: 2, textAlign: "center" }}
          >
            {t("customer.oauth")}
          </StyledText>

          <OAuthGoogle />
        </Box>
      )}
    </Box>
  );
};

export default MakeTicketRequestUserDetails;
