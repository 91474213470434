import { motion } from "framer-motion";
import {
  DialogContent,
  DialogTitle,
  IconButton,
  Modal,
  ModalClose,
  ModalDialog,
} from "@mui/joy";
import StyledText from "../text/styled_text";
import usePalette from "../../theme/use_palette";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { SxProps } from "@mui/material";

const MotionModalDialog = motion(ModalDialog);

interface AnimationOptions {
  initial?: Record<string, any>;
  animate?: Record<string, any>;
  exit?: Record<string, any>;
  transition?: Record<string, any>;
}

const defaultAnimationOptions = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0, scale: 0.5 },
  transition: { duration: 1 },
};

interface InformationModalProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  children: React.ReactNode;
  width?: string;
  animationOptions?: AnimationOptions;
  preventCloseReasons?: string[];
  zIndex?: number;
  confirmOnClose?: boolean;
  sx?: SxProps;
}

const InformationModal: React.FC<InformationModalProps> = ({
  isOpen,
  onClose,
  title,
  children,
  width = "30%",
  animationOptions = {},
  preventCloseReasons = [],
  zIndex = 1300,
  confirmOnClose = false,
  sx = {},
}) => {
  const mergedAnimationOptions = {
    ...defaultAnimationOptions,
    ...animationOptions,
  };
  const { t } = useTranslation();
  const palette = usePalette();

  const handleClose = () => {
    if (confirmOnClose) {
      const confirmClose = window.confirm("Are you sure you want to quit?");
      if (confirmClose) {
        onClose();
      }
    } else {
      onClose();
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={(_, reason: string) => {
        if (!preventCloseReasons.includes(reason)) {
          handleClose();
        }
      }}
      disableEscapeKeyDown={true}
      sx={{
        ...sx,
        zIndex: zIndex,
      }}
    >
      <MotionModalDialog
        color="primary"
        size="sm"
        variant="outlined"
        sx={{
          width: width,
          bgcolor: palette.white,
        }}
        initial={mergedAnimationOptions.initial}
        animate={mergedAnimationOptions.animate}
        exit={mergedAnimationOptions.exit}
        transition={mergedAnimationOptions.transition}
      >
        {!preventCloseReasons.includes("backdropClick") && (
          <ModalClose onClick={handleClose} />
        )}
        {preventCloseReasons.includes("backdropClick") && (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              zIndex: 2000,
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
        <DialogTitle>
          <StyledText
            level="h3"
            color={palette.primaryDark}
            fontSize={28}
            fontWeight={700}
          >
            {title ?? ""}
          </StyledText>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
      </MotionModalDialog>
    </Modal>
  );
};

export default InformationModal;
