import React from "react";
import { Box, Tooltip, Typography } from "@mui/joy";
import { ITicketType } from "../../../types";
import usePalette from "../../../theme/use_palette";
import InfoIcon from "@mui/icons-material/Info";
import { adjustTransparency, isColorDark } from "../../../utils/manager/color";
import StyledText from "../../text/styled_text";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/joy";
import { useSelector } from "react-redux";
import {
  selectAccentColor,
  selectMainColor,
} from "../../../redux/features/managerThemeSlice";
import { useTranslation } from "react-i18next";

interface TicketTypePreviewProps {
  ticketType: ITicketType;
}

const TicketTypePreview: React.FC<TicketTypePreviewProps> = ({
  ticketType,
}) => {
  const palette = usePalette();
  const theme = useTheme();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const mainColor = useSelector(selectMainColor) || palette.primaryMain;
  const accentColor = useSelector(selectAccentColor) || palette.primaryMain;
  const { t } = useTranslation();

  const calculateFontSize = (str: string) => {
    const length = str.length;
    if (length <= 10) {
      return isScreenSmall ? 16 : 24;
    } else if (length <= 20) {
      return isScreenSmall ? 14 : 20;
    } else {
      return isScreenSmall ? 12 : 18;
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: "fit-content",
        backgroundColor: adjustTransparency(palette.isabellineExtraDark, 0.1),
        borderRadius: "8px",
        border: `3px outset ${palette.primaryExtraLight}`,
        overflow: "hidden",
        display: "flex",
        flexDirection: "row",
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
        height: "auto",
        opacity: 0.8, // Add slight opacity to indicate it's a preview
      }}
    >
      {/* Left Side - Ticket Info */}
      <Box
        sx={{
          flex: 1,
          padding: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box display="flex" alignItems="center" mb={1}>
          <Tooltip
            title={ticketType.description || ""}
            placement="top"
            arrow
            sx={{
              maxWidth: 400,
            }}
          >
            <InfoIcon sx={{ color: palette.primaryDark, mr: 1 }} />
          </Tooltip>
          <StyledText
            level="body-lg"
            fontWeight={700}
            color={palette.black}
            fontSize={calculateFontSize(ticketType.name)}
            sx={{
              overflowWrap: "break-word",
              maxWidth: "100%",
            }}
          >
            {ticketType.name}
          </StyledText>
        </Box>
        <StyledText
          level="body-lg"
          fontWeight={500}
          fontSize={18}
          sx={{ color: palette.primaryDark }}
        >
          {ticketType.price === 0
            ? t("event.ticket_release.checkout.free")
            : `SEK ${ticketType.price}`}
        </StyledText>
      </Box>

      {/* Right Side - Static Display */}
    </Box>
  );
};

export default TicketTypePreview;
