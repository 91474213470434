import React from "react";
import usePalette from "../../theme/use_palette";
import { Box, Divider, Grid, useTheme } from "@mui/joy";
import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import ProductWrapper from "../product";
import StyledText from "../../components/text/styled_text";
import ProductHeroSection from "../../components/product/product_feature";
import BookDemoModal from "../../components/main/book_a_demo";
import AnimatedComponent from "../../components/animations/animated_component";
import { darkerColor } from "../../utils/manager/color";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSitemap,
  faCodeBranch,
  faProjectDiagram,
  faUsers,
  faBuilding,
  faHandshake,
} from "@fortawesome/free-solid-svg-icons";

interface EventType {
  icon: string;
  title: string;
  description: string;
}

const ComplexTeamsPage: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("lg"));
  const palette = usePalette();

  const LearnMoreRef = React.useRef<HTMLDivElement>(null);

  const cardVariants = {
    hidden: { opacity: 0, x: 50 },
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.7, ease: [0.42, 0, 0.58, 1] },
    },
  };

  const titleVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.7, ease: [0.42, 0, 0.58, 1] },
    },
  };

  const textVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.7, ease: [0.42, 0, 0.58, 1] },
    },
  };

  const eventTypeVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5, ease: [0.42, 0, 0.58, 1] },
    },
  };

  const eventIcons = {
    departments: faSitemap,
    chapters: faCodeBranch,
    projects: faProjectDiagram,
    committees: faUsers,
    venues: faBuilding,
    collaborations: faHandshake,
  };

  return (
    <ProductWrapper>
      <Helmet>
        <title>Tessera | Complex Team Management Solutions</title>
        <meta
          name="description"
          content="Discover how Tessera's complex team management solutions can help organizations manage multiple events and teams efficiently. Perfect for organizations with intricate team structures."
        />
        <meta
          name="keywords"
          content="complex teams, team management, event management, organizational structure, multi-team management, event collaboration"
        />
        <meta
          property="og:title"
          content="Tessera | Complex Team Management Solutions"
        />
        <meta
          property="og:description"
          content="Manage complex team structures and multiple events efficiently with Tessera's comprehensive team management solutions."
        />
        <meta
          property="og:url"
          content="https://tesseratickets.se/solutions/complex-teams"
        />
        <meta property="og:type" content="website" />
        <link
          rel="canonical"
          href="https://tesseratickets.se/solutions/complex-teams"
        />
      </Helmet>

      <ProductHeroSection
        title={t("solutions.complex_teams.title")}
        description={t("solutions.complex_teams.description")}
        nextRef={LearnMoreRef}
      />

      <Box>
        <Grid
          container
          spacing={4}
          sx={{
            px: isScreenSmall ? 2 : 10,
            py: 10,
          }}
        >
          <Grid
            sm={12}
            lg={5}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: isScreenSmall ? "center" : "left",
            }}
          >
            <Box
              sx={{
                mr: isScreenSmall ? "auto" : 10,
                mx: isScreenSmall ? 2 : "auto",
              }}
            >
              <AnimatedComponent variants={titleVariants}>
                <StyledText
                  level="h2"
                  color={palette.primaryMain}
                  fontWeight={700}
                  fontSize={38}
                >
                  {t("solutions.complex_teams.section_1.title")}
                </StyledText>
              </AnimatedComponent>
              <AnimatedComponent variants={textVariants}>
                <StyledText
                  level="body1"
                  color={palette.text}
                  fontSize={20}
                  sx={{ lineHeight: "30px" }}
                >
                  {t("solutions.complex_teams.section_1.description")}
                  <Divider style={{ visibility: "hidden", margin: "5px 0" }} />
                  {t("solutions.complex_teams.section_1.description_2")}
                </StyledText>
              </AnimatedComponent>
              <Box sx={{ mt: 3 }}>
                <BookDemoModal buttonSize="sm" fontSize={20} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Grid
          container
          sx={{
            backgroundColor: darkerColor(palette.offWhite, 0.85),
            py: 10,
          }}
        >
          <Grid
            sm={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: isScreenSmall ? "center" : "left",
              mb: isScreenSmall ? 5 : 0,
            }}
          >
            <AnimatedComponent variants={titleVariants}>
              <StyledText
                level="h2"
                color={palette.primaryMain}
                fontWeight={700}
                fontSize={38}
              >
                {t("solutions.complex_teams.section_2.title")}
              </StyledText>
              <StyledText
                level="body1"
                color={palette.text}
                fontSize={20}
                sx={{ lineHeight: "30px", maxWidth: 700 }}
              >
                {t("solutions.complex_teams.section_2.description")}
                <Divider style={{ visibility: "hidden", margin: "5px 0" }} />
                {t("solutions.complex_teams.section_2.description_2")}
              </StyledText>
            </AnimatedComponent>
          </Grid>
        </Grid>
      </Box>

      <Divider style={{ visibility: "hidden", margin: "64px 0" }} />

      <Box sx={{ py: 10, px: isScreenSmall ? 2 : 10 }}>
        <AnimatedComponent variants={titleVariants}>
          <StyledText
            level="h2"
            color={palette.primaryMain}
            fontWeight={700}
            fontSize={38}
            sx={{ textAlign: "center", mb: 2 }}
          >
            {t("solutions.complex_teams.event_types.title")}
          </StyledText>
          <StyledText
            level="body1"
            color={palette.text}
            fontSize={20}
            sx={{ textAlign: "center", mb: 6 }}
          >
            {t("solutions.complex_teams.event_types.description")}
          </StyledText>
        </AnimatedComponent>

        <Grid container spacing={4} justifyContent="center">
          {(
            t("solutions.complex_teams.event_types.types", {
              returnObjects: true,
            }) as EventType[]
          ).map((type, index) => (
            <Grid key={type.title} xs={12} sm={6} md={4} component="div">
              <AnimatedComponent
                variants={eventTypeVariants}
                style={{
                  height: "100%",
                }}
                threshold={0.2}
              >
                <Box
                  sx={{
                    p: 3,
                    height: "100%",
                    borderRadius: 2,
                    bgcolor: palette.white,
                    boxShadow: "sm",
                    transition: "all 0.3s ease",
                    "&:hover": {
                      transform: "translateY(-5px)",
                      boxShadow: "md",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: 2,
                    }}
                  >
                    <Box
                      sx={{
                        color: palette.primaryMain,
                        fontSize: "32px",
                        mr: 2,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={eventIcons[type.icon as keyof typeof eventIcons]}
                      />
                    </Box>
                    <StyledText
                      level="h3"
                      color={palette.text}
                      fontWeight={600}
                      fontSize={24}
                    >
                      {type.title}
                    </StyledText>
                  </Box>
                  <StyledText level="body1" color={palette.text} fontSize={16}>
                    {type.description}
                  </StyledText>
                </Box>
              </AnimatedComponent>
            </Grid>
          ))}
        </Grid>
      </Box>
    </ProductWrapper>
  );
};

export default ComplexTeamsPage;
