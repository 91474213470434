import React from "react";
import { Helmet } from "react-helmet";
import { IEvent } from "../../types";

interface PrivateEventSEOProps {
  event: IEvent;
}

const PrivateEventSEO: React.FC<PrivateEventSEOProps> = ({ event }) => {
  return (
    <>
      <Helmet>
        <title>{event.name} - Private Event</title>
        <meta
          name="description"
          content="This is a private event and not publicly listed."
        />
        <meta name="robots" content="noindex, nofollow" />
        <link rel="canonical" href="https://tesseratickets.se" />
      </Helmet>
    </>
  );
};

export default PrivateEventSEO;
