import React, { useEffect } from "react";
import { Box, Grid, Typography, styled, useTheme } from "@mui/joy";
import Navigation from "../../components/navigation";

import { useTranslation } from "react-i18next";

import { isMobile } from "react-device-detect";
import { useMediaQuery } from "@mui/material";
import Footer from "../../components/wrappers/footer";

import GetStarted from "../../components/main/get_started";
import { ToastContainer } from "react-toastify";
import TesseraWrapper from "../../components/wrappers/page_wrapper";

interface ProductWrapperProps {
  children: React.ReactNode;
}

const ProductWrapper: React.FC<ProductWrapperProps> = ({ children }) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <TesseraWrapper defaultColors={true}>
      {children}
      {/* Hero Section with Value Proposition */}
      <section>
        <Box
          sx={{
            mt: 20,
          }}
        >
          <GetStarted />
        </Box>
      </section>

      {/* Footer */}
    </TesseraWrapper>
  );
};

export default ProductWrapper;
