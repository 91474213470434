// FormFile.tsx
import React from "react";
import { Field } from "formik";
import { Box, IconButton, Input, Modal } from "@mui/joy";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import usePalette from "../../theme/use_palette";
import StyledText from "../text/styled_text";
import { Palette } from "@mui/icons-material";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

interface FormFileProps {
  name: string; // e.g. "14"
  required?: boolean;
  multiple?: boolean;
  existingUrl?: string;
  onRemove?: () => void;
}

export const ExistingFilePreview: React.FC<{
  previewUrl: string;
  setPreviewUrl: (val: string | null) => void;
  onRemove: () => void;
}> = ({ previewUrl, setPreviewUrl, onRemove }) => {
  const palette = usePalette();
  return (
    <>
      {/* If you prefer an inline row with icons, you can do that here,
            or let the parent handle the eye/delete icons. */}
      <Modal
        open={!!previewUrl}
        onClose={() => setPreviewUrl(null)}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            position: "relative",
            maxWidth: "90vw",
            maxHeight: "90vh",
            overflow: "auto",
            bgcolor: "background.surface",
            borderRadius: "md",
            p: 1,
          }}
        >
          <img
            src={previewUrl || ""}
            alt="Preview"
            style={{
              maxWidth: "100%",
              maxHeight: "80vh",
              objectFit: "contain",
            }}
          />
          <IconButton
            onClick={() => {
              setPreviewUrl(null);
              onRemove();
            }}
            sx={{
              position: "absolute",
              top: 16,
              right: 16,
              bgcolor: "background.surface",
              "&:hover": { bgcolor: palette.errorMain },
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </Modal>
    </>
  );
};

export const FormFile: React.FC<FormFileProps> = ({
  name,
  existingUrl,
  required = false,
  multiple = false,
}) => {
  const palette = usePalette();
  const { t } = useTranslation();
  return (
    <Field name={name}>
      {({ field, form }: any) => {
        const currentVal = field.value || {};

        return (
          <>
            <Input
              slotProps={{
                input: {
                  type: "file",
                  accept: "image/*",
                  multiple,
                  // Add a key to force re-render when existingUrl changes
                  key: existingUrl || "no-file",
                  onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                    const files = event.currentTarget.files;
                    if (!files || files.length === 0) {
                      return;
                    }

                    form.setFieldValue(name, {
                      ...currentVal,
                      file: multiple ? Array.from(files) : files[0],
                      remove: false,
                    });
                  },
                },
              }}
              // Use data attribute to show filename in input
              data-filename={existingUrl}
              required={required}
              // Add custom styles to show filename
            />
          </>
        );
      }}
    </Field>
  );
};

export default FormFile;
