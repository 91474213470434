// src/components/modals/BookDemoModal.tsx
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import InformationModal from "../modal/information";
import usePalette from "../../theme/use_palette";
import StyledButton from "../buttons/styled_button";
import ReactGA from "react-ga";

const BookDemoButton: React.FC<{
  buttonSize?: "sm" | "md" | "lg";
  fontSize?: number;
}> = ({ buttonSize, fontSize }) => {
  const { t } = useTranslation();
  const palette = usePalette();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
    ReactGA.event({
      category: "Book Demo",
      action: "Opened Book Demo Modal",
      label: "calendar_modal_opened",
    });
  };

  return (
    <div style={{ margin: 0 }}>
      <StyledButton
        size={buttonSize || "md"}
        onClick={handleOpenModal}
        color={palette.black}
        bgColor={palette.offWhite}
        fs={fontSize || 22}
      >
        {t("book_demo.button_text")}
      </StyledButton>

      <InformationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={t("book_demo.title")}
        width="80%"
        sx={{
          "& .MuiModalDialog-root": {
            backgroundColor: palette.alwaysWhite,
          },
        }}
      >
        <iframe
          src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ209AdWxSQrVfshWnvU2Lm2PxACqXhORAKdwtUGcWTg8MR3YZ9Y6j3OCCferG-tbkSODj2-CXh6?gv=true"
          style={{
            border: 0,
            width: "100%",
            height: "900px",
            borderRadius: "8px",
          }}
          frameBorder="0"
          title="Book a Demo Calendar"
        />
      </InformationModal>
    </div>
  );
};

export default BookDemoButton;
