import { ToastContainer } from "react-toastify";

const StandardToastContainer = (props: any) => (
  <ToastContainer
    position="bottom-right"
    autoClose={2500}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    theme="light"
  />
);

export default StandardToastContainer;
