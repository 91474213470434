import { Box, useTheme } from "@mui/joy";
import usePalette from "../../theme/use_palette";
import { adjustTransparency, getRandomColor } from "../../utils/manager/color";
import StyledText from "../text/styled_text";
import { useMediaQuery } from "@mui/material";
import CallToActionButton from "../buttons/call_to_action_button";
import { Trans, useTranslation } from "react-i18next";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import React, { useEffect, useState } from "react";
import classes from "./product.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import BlurryBackground from "../backgrounds/blurry_background";

interface ProductHeroSectionProps {
  title: string;
  description: string;
  nextRef: React.RefObject<HTMLDivElement>;
  backgroundImage?: string;
  backgroundIcons?: IconDefinition[];
}

interface FloatingIconProps {
  icon: IconDefinition;
  size: string;
  color: string;
  delay: number;
  duration: number;
  x: number;
  y: number;
}

interface IconPosition {
  x: number;
  y: number;
  size: number;
  delay: number;
  duration: number;
}

const FloatingIcon: React.FC<FloatingIconProps> = ({
  icon,
  size,
  color,
  delay,
  duration,
  x,
  y,
}) => {
  const floatVariants = {
    initial: { opacity: 0, x, y },
    animate: {
      opacity: [0, 0.6, 0.4],
      x: [x, x + 10, x],
      y: [y, y - 10, y],
      transition: {
        delay,
        duration: duration * 1.5,
        repeat: Infinity,
        repeatType: "reverse" as const,
      },
    },
  };

  return (
    <motion.div
      initial="initial"
      animate="animate"
      variants={floatVariants}
      style={{
        position: "absolute",
        zIndex: 1,
      }}
    >
      <FontAwesomeIcon icon={icon} style={{ fontSize: size, color }} />
    </motion.div>
  );
};

const ProductHeroSection: React.FC<ProductHeroSectionProps> = ({
  nextRef,
  title,
  description,
  backgroundImage,
  backgroundIcons = [],
}) => {
  const theme = useTheme();
  const palette = usePalette();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const [iconPositions, setIconPositions] = useState<IconPosition[]>([]);

  const titleControls = useAnimation();
  const [titleRef, titleInView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const descriptionControls = useAnimation();
  const [descriptionRef, descriptionInView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (titleInView) {
      titleControls.start("visible");
    }
    if (descriptionInView) {
      descriptionControls.start("visible");
    }
  }, [titleControls, titleInView, descriptionControls, descriptionInView]);

  const titleVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.9, ease: [0.42, 0.75, 0.99, 1] },
    },
  };

  const descriptionVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5, ease: [0.42, 0, 0.58, 1] },
    },
  };

  const generateRandomPosition = (index: number, total: number) => {
    // Calculate angle for circular distribution
    const angle = (index / total) * Math.PI * 2;

    // Use window width for horizontal distribution
    const horizontalRadius = window.innerWidth * 0.4;
    const verticalRadius = window.innerHeight * 0.25;

    // Add vertical offset to keep icons away from navigation
    const verticalOffset = window.innerHeight * 0.2;

    // Calculate base position with different radiuses for x and y
    const x = Math.cos(angle) * horizontalRadius + window.innerWidth / 2;
    const y = Math.sin(angle) * verticalRadius + verticalOffset;

    // Add some controlled randomness
    const randomOffset = {
      x: (Math.random() - 0.5) * (window.innerWidth * 0.1),
      y: Math.random() * (window.innerHeight * 0.05),
    };

    const baseSize = isScreenSmall ? 16 : 24;
    const sizeVariation = isScreenSmall ? 4 : 8;

    return {
      x: x + randomOffset.x,
      y: y + randomOffset.y,
      size: baseSize + Math.random() * sizeVariation,
      delay: Math.random() * 3,
      duration: Math.random() * 2 + 3,
    };
  };

  // Generate new positions periodically
  useEffect(() => {
    const generatePositions = () => {
      const newPositions = backgroundIcons.map((_, index) =>
        generateRandomPosition(index, backgroundIcons.length)
      );
      setIconPositions(newPositions);
    };

    // Initial generation
    generatePositions();

    // Regenerate positions every 10 seconds
    const interval = setInterval(generatePositions, 10000);

    return () => clearInterval(interval);
  }, [backgroundIcons.length, isScreenSmall]);

  const [blurryShapes, setBlurryShapes] = useState<
    Array<{ rx: number; ry: number; cx: number; cy: number; fill: string }>
  >([]);

  useEffect(() => {
    const generateRandomShape = () => ({
      rx: Math.random() * 200 + 100, // Random radius x between 100 and 300
      ry: Math.random() * 50 + 100, // Random radius y between 100 and 300
      cx: Math.random() * window.innerWidth, // Random center x within window width
      cy: Math.random() * (window.innerHeight - 500), // Random center y within window height
      fill: adjustTransparency(getRandomColor(), Math.random() * 0.6 + 0.4), // Random transparency
    });

    const shapes = Array.from({ length: 5 }, generateRandomShape); // Generate 5 random shapes
    setBlurryShapes(shapes);
  }, []); // Only run on first render

  return (
    <Box
      sx={{
        height: !isScreenSmall ? "90vh" : "120vh",
        position: "relative",
        overflow: "hidden",
        "&::before": backgroundImage
          ? {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: `url(${backgroundImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              opacity: 0.15,
              zIndex: 0,
            }
          : {},
      }}
    >
      <BlurryBackground
        shapes={blurryShapes}
        blurStdDeviation={100}
        opacity={0.5}
        scale={1}
        width={"100%"}
        height={"100%"}
        containerHeight="100%"
        style={{ zIndex: 0 }}
      />
      {/* Background gradient overlay */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1,
        }}
      />

      {/* Floating background icons */}
      {backgroundIcons.map((icon, index) => {
        const position =
          iconPositions[index] ||
          generateRandomPosition(index, backgroundIcons.length);

        return (
          <FloatingIcon
            key={`${index}-${position.x}-${position.y}`}
            icon={icon}
            size={`${position.size}px`}
            color={adjustTransparency(palette.black, 0.25)}
            delay={position.delay}
            duration={position.duration}
            x={position.x}
            y={position.y}
          />
        );
      })}

      <Box sx={{ minHeight: 180, position: "relative", zIndex: 2 }}>
        <div className={classes.bowl}>
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M600,112.77C268.63,112.77,0,65.52,0,7.23V120H1200V7.23C1200,65.52,931.37,112.77,600,112.77Z"
              className={classes.shape_fill}
            ></path>
          </svg>
        </div>
        <Box
          sx={{
            position: "absolute",
            transform: "translateX(-50%)",
            left: "50%",
            height: "750px",
            mt: 25,
            width: "90%",
          }}
        >
          <motion.div
            ref={titleRef}
            initial="hidden"
            animate={titleControls}
            variants={titleVariants}
          >
            <StyledText
              level="h1"
              color={palette.black}
              fontSize={isScreenSmall ? 68 : 110}
              fontWeight={700}
              sx={{
                textAlign: "center",
                position: "relative",
                zIndex: 2,
                textShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Trans
                i18nKey="product.titles.features"
                components={{
                  1: (
                    <Box
                      component="span"
                      sx={{
                        color: palette.primaryMain,
                        textDecoration: "underline",
                        textDecorationThickness: 5,
                        textDecorationLine: "solid",
                        textUnderlineOffset: 10,
                        position: "relative",
                        "&::after": {
                          content: '""',
                          position: "absolute",
                          bottom: -2,
                          left: 0,
                          right: 0,
                          height: "2px",
                          background: (theme) =>
                            `linear-gradient(90deg, transparent, ${palette.primaryMain}, transparent)`,
                        },
                      }}
                    />
                  ),
                }}
              >
                {t(title)}
              </Trans>
            </StyledText>
          </motion.div>
          <motion.div
            ref={descriptionRef}
            initial="hidden"
            animate={descriptionControls}
            variants={descriptionVariants}
          >
            <StyledText
              level="body"
              color={palette.black}
              fontSize={isScreenSmall ? 18 : 32}
              fontWeight={400}
              sx={{
                textAlign: "center",
                width: isScreenSmall ? "80%" : "100%",
                margin: "0 auto",
                textWrap: "balance",
                position: "relative",
                zIndex: 2,
                textShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
              }}
            >
              {description}
            </StyledText>
          </motion.div>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              height: "80%",
              mt: 10,
              position: "relative",
              zIndex: 2,
            }}
          >
            <CallToActionButton title="Learn more" scrollRef={nextRef} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProductHeroSection;
