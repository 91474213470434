// src/components/manager/tessera_ai/sidebar/sidebar_container.tsx

import React, { useEffect, useState } from "react";
import { Box, IconButton, Sheet, Dropdown, MenuButton, Modal } from "@mui/joy";
import CloseIcon from "@mui/icons-material/Close";
import ChatIcon from "@mui/icons-material/Chat";
import ViewSidebarIcon from "@mui/icons-material/ViewSidebar";
import WebAssetIcon from "@mui/icons-material/WebAsset";
import usePalette from "../../../../theme/use_palette";
import SidebarChatMessages from "./sidebar_chat_messages";
import SidebarChatInputForm from "./sidebar_chat_input_form";
import SessionsMenu from "./sessions_menu";
import {
  fetchSessionsRequest,
  setCurrentSession,
} from "../../../../redux/features/manager/ai/aiChatSlice";
import { getMyOrganizationsRequest } from "../../../../redux/features/organizationSlice";
import { AppDispatch, RootState } from "../../../../store";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateSessionName } from "../../../../redux/sagas/axios_calls/ai/change_ai_session_name";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import Input from "@mui/material/Input";
import StyledText from "../../../text/styled_text";
import { useTranslation } from "react-i18next";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

interface SidebarContainerProps {
  open: boolean;
  onClose: () => void;
  organizationId?: number;
  eventId?: number;
}

const SidebarContainer: React.FC<SidebarContainerProps> = ({
  open,
  onClose,
  organizationId,
  eventId,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [sessionName, setSessionName] = useState("");
  const [isModalMode, setIsModalMode] = useState(false);
  const { t } = useTranslation();
  const palette = usePalette();

  const { currentSession } = useSelector((state: RootState) => state.aiChat);

  useEffect(() => {
    dispatch(fetchSessionsRequest());
    dispatch(getMyOrganizationsRequest());
  }, [dispatch]);

  useEffect(() => {
    if (currentSession) {
      const eventName =
        currentSession.name ||
        currentSession.messages.find((msg) => msg.data?.event?.name)?.data
          ?.event?.name ||
        "New Chat";
      setSessionName(eventName);
    }
  }, [currentSession]);

  const handleNameUpdate = async () => {
    if (currentSession && sessionName.trim()) {
      try {
        await updateSessionName(currentSession.id, sessionName);
        setIsEditing(false);
        dispatch(fetchSessionsRequest()); // Refresh sessions after update
      } catch (error) {
        console.error("Failed to update session name:", error);
      }
    }
  };

  const handleNewChat = () => {
    dispatch(setCurrentSession(null)); // Reset current session
    setSessionName("New Chat");
  };

  const toggleDisplayMode = () => {
    setIsModalMode(!isModalMode);
  };

  const content = (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          p: 2,
          borderBottom: "1px solid",
          borderColor: "divider",
          justifyContent: "space-between",
          minHeight: "64px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            flex: "1 1 auto",
            minWidth: 0, // This prevents flex items from overflowing
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              flexShrink: 0,
            }}
          >
            <Dropdown>
              <MenuButton>
                <IconButton size="sm">
                  <ChatIcon
                    sx={{
                      fontSize: 24,
                      color: palette.primaryMain,
                    }}
                  />
                </IconButton>
              </MenuButton>
              <SessionsMenu />
            </Dropdown>
            <IconButton
              size="sm"
              onClick={handleNewChat}
              sx={{
                color: palette.primaryMain,
                "&:hover": {
                  backgroundColor: palette.primaryExtraLight,
                },
              }}
            >
              <AddCircleOutlineIcon sx={{ fontSize: 24 }} />
            </IconButton>
          </Box>

          <Box sx={{ flex: "1 1 auto", minWidth: 0, overflow: "hidden" }}>
            {isEditing ? (
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Input
                  value={sessionName}
                  onChange={(e) => setSessionName(e.target.value)}
                  sx={{ flex: 1 }}
                />
                <IconButton onClick={handleNameUpdate} size="sm">
                  <CheckIcon />
                </IconButton>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <StyledText
                  level="body-md"
                  fontWeight={600}
                  color={palette.primaryDark}
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textWrap: "nowrap",
                    maxWidth: "150px",
                  }}
                >
                  {sessionName}
                </StyledText>
                {currentSession && (
                  <IconButton onClick={() => setIsEditing(true)} size="sm">
                    <EditIcon
                      sx={{
                        fontSize: 20,
                      }}
                    />
                  </IconButton>
                )}
              </Box>
            )}
          </Box>
        </Box>

        <Box sx={{ display: "flex", gap: 1, flexShrink: 0, ml: 2 }}>
          <IconButton onClick={toggleDisplayMode} size="sm">
            {isModalMode ? (
              <ViewSidebarIcon
                sx={{
                  color: palette.charcoal,
                }}
              />
            ) : (
              <WebAssetIcon
                sx={{
                  color: palette.charcoal,
                }}
              />
            )}
          </IconButton>
          <IconButton onClick={onClose}>
            <CloseIcon
              sx={{
                color: palette.charcoal,
              }}
            />
          </IconButton>
        </Box>
      </Box>

      <Box sx={{ flex: 1, overflowY: "auto", p: 2 }}>
        <SidebarChatMessages />
      </Box>

      <Box sx={{ p: 1, borderTop: "1px solid", borderColor: "divider" }}>
        <SidebarChatInputForm
          organizationId={organizationId}
          eventId={eventId}
        />
      </Box>
      <Box>
        <StyledText
          level="body-md"
          color={palette.primaryDark}
          fontSize={12}
          sx={{
            textAlign: "center",
            my: 0.25,
          }}
        >
          {t("manager.ai.mistakes")}
        </StyledText>
      </Box>
    </>
  );

  if (isModalMode) {
    return (
      <Modal
        open={open}
        onClose={onClose}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Sheet
          sx={{
            width: { xs: "95%", sm: "80%", md: "60%" },
            height: "80vh",
            bgcolor: palette.white,
            borderRadius: "12px",
            boxShadow: "0px 4px 20px rgba(0,0,0,0.15)",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {content}
        </Sheet>
      </Modal>
    );
  }

  return (
    <Sheet
      sx={{
        position: "fixed",
        top: 0,
        right: open ? 0 : "-400px",
        width: { xs: "100%", md: 400 },
        height: "100vh",
        bgcolor: palette.white,
        boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
        zIndex: 2000,
        transition: "right 0.3s ease-in-out",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {content}
    </Sheet>
  );
};

export default SidebarContainer;
