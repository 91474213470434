import { useMemo } from "react";
import { ITicket, ITicketOrder } from "../../../types";

export const useCosts = (ticketOrder: ITicketOrder) => {
  const totalTicketCost = useMemo(() => {
    if (!ticketOrder.tickets || ticketOrder.tickets.length === 0) {
      return 0;
    }

    return ticketOrder.tickets.reduce((total, ticket) => {
      return total + (ticket?.ticket_type?.price ?? 0);
    }, 0);
  }, [ticketOrder]);

  const totalAddonsCost = useMemo(() => {
    return ticketOrder.tickets.reduce((total, ticket) => {
      return (
        total +
        (ticket?.ticket_add_ons?.reduce((total, addon) => {
          const addonDetails = ticketOrder?.ticket_release?.add_ons?.find(
            (a) => a.id === addon.add_on_id
          );
          return total + (addonDetails?.price ?? 0) * (addon.quantity ?? 0);
        }, 0) ?? 0)
      );
    }, 0);
  }, [ticketOrder]);

  const totalCost = useMemo(
    () => totalTicketCost + totalAddonsCost,
    [totalTicketCost, totalAddonsCost]
  );

  return { totalTicketCost, totalAddonsCost, totalCost };
};

export const useTicketCost = (tickets: ITicket[]) => {
  const totalTicketCost = useMemo(() => {
    return tickets.reduce((total, ticket) => {
      return total + (ticket?.ticket_type?.price ?? 0);
    }, 0);
  }, [tickets]);

  const totalAddonsCost = useMemo(() => {
    return tickets.reduce((total, ticket) => {
      const ticketAddonsCost =
        ticket?.ticket_add_ons?.reduce((addonTotal, addon) => {
          return addonTotal + (addon.add_on?.price ?? 0) * (addon.quantity ?? 0);
        }, 0) ?? 0;
      return total + ticketAddonsCost;
    }, 0);
  }, [tickets]);

  const totalCost = useMemo(
    () => totalTicketCost + totalAddonsCost,
    [totalTicketCost, totalAddonsCost]
  );

  return { totalTicketCost, totalAddonsCost, totalCost };
};
