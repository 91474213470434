import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  AccordionGroup,
  accordionSummaryClasses,
  accordionClasses,
  IconButton,
} from "@mui/joy";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ITicket, IEventFormField } from "../../../types";
import { Formik } from "formik";
import * as Yup from "yup";
import StyledButton from "../../buttons/styled_button";
import StyledText from "../../text/styled_text";
import usePalette, { useIsDarkTheme } from "../../../theme/use_palette";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { submitFormFieldResponseRequest } from "../../../redux/features/formFieldsSlice";
import { RootState } from "../../../store";
import FormSelect, { ISelectOption } from "../../forms/form_select";
import FormMultiSelect from "../../forms/form_multi_select";
import {
  StyledFormLabel,
  StyledFormLabelWithHelperText,
} from "../../forms/form_labels";
import { FormCheckbox, FormInput, FormTextarea } from "../../forms/input_types";
import { StyledErrorMessage } from "../../forms/messages";
import { darkerColor, lightenColor } from "../../../utils/manager/color";
import { selectAccentColor } from "../../../redux/features/managerThemeSlice";
import FormFile from "../../forms/form_file";
import DeleteIcon from "@mui/icons-material/Delete";
import ImageField from "./fields/image_field";

interface EditFormFieldResponseProps {
  formFields: IEventFormField[];
  tickets: ITicket[];
  isGuestCustomer?: boolean;
  eventID: number;
  hideSubmitButton?: boolean;
  refetch?: () => void;
  onFormValidityChange?: (isValid: boolean) => void;
  onFormSaveStatusChange?: (isSaved: boolean) => void;
}

const createField = (field: IEventFormField, formik: any) => {
  const fieldValue = formik.values[field.id.toString()];

  const transformOptions = (options: string[]): ISelectOption[] => {
    return options.map((option, index) => ({
      id: index,
      value: option,
      label: option,
    }));
  };

  const getFieldType = (type: string, field: IEventFormField) => {
    switch (type) {
      case "text":
        return (
          <FormTextarea
            name={field.id.toString()}
            label="text"
            placeholder="Enter text"
            required={field.is_required}
            overrideStyle={{ width: "90%" }}
          />
        );
      case "number":
        return (
          <FormInput
            name={field.id.toString()}
            label="number"
            type="number"
            placeholder="Enter number"
            required={field.is_required}
          />
        );
      case "checkbox":
        return <FormCheckbox name={field.id.toString()} label="checkbox" />;
      case "select":
        return (
          <FormSelect
            name={field.id.toString()}
            options={transformOptions(field.options || [])}
            label="select"
            required={field.is_required}
            style={{
              color: "black",
            }}
          />
        );
      case "multiselect":
        return (
          <FormMultiSelect
            name={field.id.toString()}
            label="multiselect"
            options={transformOptions(field.options || [])}
            required={field.is_required}
          />
        );
      case "image":
        return <ImageField field={field} formik={formik} />;
      default:
        return null;
    }
  };

  return (
    <Box
      key={field.id}
      sx={{
        my: 0.5,
      }}
    >
      <StyledFormLabel fontSize={14}>{field.name}</StyledFormLabel>
      {getFieldType(field.type, field)}
      <StyledFormLabelWithHelperText fontSize={12}>
        {field.description}
      </StyledFormLabelWithHelperText>
      <StyledErrorMessage name={field.id.toString()} />
    </Box>
  );
};

const EditFormFieldResponse: React.FC<EditFormFieldResponseProps> = ({
  formFields,
  tickets,
  eventID,
  isGuestCustomer = false,
  hideSubmitButton = false,
  refetch,
  onFormValidityChange,
  onFormSaveStatusChange,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { guestCustomer } = useSelector(
    (state: RootState) => state.guestCustomer
  );

  const accentColor = useSelector(selectAccentColor);
  const palette = usePalette();
  const darkMode = useIsDarkTheme();

  const [expanded, setExpanded] = useState<string | false>(false);
  const [savedForms, setSavedForms] = useState<{ [key: string]: boolean }>({});
  const formikRefs = useRef<{ [key: string]: any }>({});

  // Track form validity changes
  useEffect(() => {
    const interval = setInterval(() => {
      const allFormsValid = tickets.every((ticket) => {
        const formikInstance = formikRefs.current[ticket.id];
        return formikInstance?.isValid;
      });
      onFormValidityChange?.(allFormsValid);
    }, 100);

    return () => clearInterval(interval);
  }, [tickets, onFormValidityChange]);

  // Track save status
  useEffect(() => {
    const allFormsSaved = tickets.every((ticket) => savedForms[ticket.id]);
    onFormSaveStatusChange?.(allFormsSaved);
  }, [savedForms, tickets, onFormSaveStatusChange]);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleSubmit = async (values: any, ticketId: number) => {
    let textBasedValues: Array<{
      event_form_field_id: number;
      value: string | string[];
    }> = [];

    const formData = new FormData();

    formFields.forEach((field) => {
      const fieldKey = field.id.toString();
      const rawValue = values[fieldKey];

      if (field.type === "image") {
        // rawValue is { existingUrl, remove, file }
        const { existingUrl, remove, file } = rawValue || {};

        if (file) {
          // user uploaded a new file => we append it to formData
          formData.append(`image_field_${field.id}`, file);

          // We'll push an empty string for the text-based portion
          textBasedValues.push({
            event_form_field_id: field.id,
            value: "",
          });
        } else if (remove) {
          // user explicitly removed => store empty string to remove from DB
          textBasedValues.push({
            event_form_field_id: field.id,
            value: "",
          });
        } else {
          // user did not upload or remove => keep the old URL
          textBasedValues.push({
            event_form_field_id: field.id,
            value: existingUrl || "",
          });
        }
      } else {
        // Text-based fields or checkboxes, etc.
        // same logic as before
        let storedValue = "";
        if (Array.isArray(rawValue)) {
          storedValue = JSON.stringify(rawValue);
        } else {
          storedValue = rawValue?.toString() || "";
        }
        textBasedValues.push({
          event_form_field_id: field.id,
          value: storedValue,
        });
      }
    });

    formData.append("data", JSON.stringify(textBasedValues));

    // Dispatch your action with the combined formData
    dispatch(
      submitFormFieldResponseRequest({
        formData,
        event_id: eventID,
        ticketId,
        isGuestCustomer,
        guestCustomer: isGuestCustomer ? guestCustomer! : undefined,
      })
    );

    // Mark this form as saved
    setSavedForms((prev) => ({ ...prev, [ticketId]: true }));

    setTimeout(() => {
      refetch && refetch();
    }, 500);
  };

  const createValidationSchema = () => {
    let schema: any = {};
    formFields.forEach((field) => {
      switch (field.type) {
        case "text":
          schema[field.id.toString()] = field.is_required
            ? Yup.string().required(`${field.name} is required`)
            : Yup.string();
          break;
        case "number":
          schema[field.id.toString()] = field.is_required
            ? Yup.number().required(`${field.name} is required`)
            : Yup.number();
          break;
        case "checkbox":
          schema[field.id.toString()] = field.is_required
            ? Yup.boolean().oneOf([true], `${field.name} must be checked`)
            : Yup.boolean();
          break;
        case "select":
          schema[field.id.toString()] = field.is_required
            ? Yup.string().required(`${field.name} is required`)
            : Yup.string().nullable();
          break;
        case "multiselect":
          schema[field.id.toString()] = field.is_required
            ? Yup.array().min(1, `${field.name} is required`)
            : Yup.array().nullable();
          break;
        case "image":
          schema[field.id.toString()] = field.is_required
            ? Yup.mixed().required(`${field.name} is required`)
            : Yup.mixed().nullable();
          break;
        default:
          break;
      }
    });
    return Yup.object().shape(schema);
  };

  const createInitialValues = (ticket: ITicket) => {
    const values = ticket.event_form_responses || [];
    let initialValues: any = {};

    formFields.forEach((field) => {
      const response = values.find(
        (value) => value.event_form_field_id === field.id
      );

      switch (field.type) {
        case "select":
          // Ensure select value is a string
          initialValues[field.id.toString()] = response?.value || "";
          break;
        case "multiselect":
          // Parse string array from JSON if stored as string, or use empty array
          initialValues[field.id.toString()] = response?.value
            ? typeof response.value === "string"
              ? JSON.parse(response.value)
              : response.value
            : [];
          break;
        case "checkbox":
          // Convert string 'true'/'false' to boolean
          initialValues[field.id.toString()] = response?.value === "true";
          break;
        case "image":
          const existingUrl =
            response?.value && typeof response.value === "string"
              ? response.value
              : "";
          initialValues[field.id.toString()] = {
            existingUrl, // keep the old url if present
            remove: false, // by default, user does NOT want to remove
            file: null, // no new file yet
          };
          break;
        default:
          initialValues[field.id.toString()] = response?.value || "";
      }
    });
    return initialValues;
  };

  const handleFormikRef = (ticketId: number, formik: any) => {
    formikRefs.current[ticketId] = formik;
  };

  return (
    <Box>
      <StyledText level="body-sm" fontSize={14} color={palette.textSecondary}>
        {t("event_form_fields.description")}
      </StyledText>

      <AccordionGroup
        disableDivider
        transition={{
          initial: "0.3s ease-out",
          expanded: "0.2s ease",
        }}
        sx={{
          mt: 1,
          [`& .${accordionSummaryClasses.indicator}`]: {
            transition: "0.2s",
          },
          [`& .custom-accordion [aria-expanded="true"] .${accordionSummaryClasses.indicator}`]:
            {
              transform: "rotate(45deg)",
            },
          [`& .${accordionClasses.root}`]: {
            maxWidth: 500,
            marginTop: "0.5rem",
            transition: "0.2s ease",
            '& button:not([aria-expanded="true"])': {
              transition: "0.2s ease",
              paddingBottom: "0.625rem",
            },
          },
          [`& .${accordionClasses.root}.${accordionClasses.expanded}`]: {
            bgcolor: !darkMode
              ? lightenColor(palette.secondaryDark, 0.6)
              : darkerColor(palette.secondaryDark, 0.6),
            borderRadius: "md",
            borderBottom: "1px solid",
            borderColor: palette.accentMain,
          },
          '& .custom-accordion [aria-expanded="true"]': {
            boxShadow: `inset 0 -1px 0 ${palette.charcoal}`,
          },
          [`& .custom-accordion [aria-expanded="false"]`]: {
            border: `1px solid ${palette.charcoal}`,
            borderRadius: "md",
            backgroundColor: palette.white,
            color: palette.alwaysBlack,
            transition: "background-color 0.2s ease",
            "&:hover": {
              backgroundColor: palette.greyLight,
            },
          },
        }}
      >
        {tickets.map((ticket, index) => {
          const isDeleted = !!ticket.deleted_at;
          return (
            <Formik
              key={ticket.id}
              initialValues={createInitialValues(ticket)}
              validationSchema={createValidationSchema()}
              validateOnMount={true}
              validateOnChange={true}
              validateOnBlur={true}
              onSubmit={(values) => handleSubmit(values, ticket.id)}
            >
              {(formik) => {
                // Update formik ref directly
                formikRefs.current[ticket.id] = formik;

                return (
                  <Accordion
                    key={ticket.id}
                    disabled={isDeleted}
                    expanded={expanded === ticket.id.toString()}
                    onChange={handleChange(ticket.id.toString())}
                    className={`custom-accordion ${
                      expanded !== ticket.id.toString() && !formik.isValid
                        ? "form-field-accordion-closed"
                        : ""
                    }`}
                  >
                    <AccordionSummary
                      aria-controls={`panel${index}-content`}
                      id={`panel${index}-header`}
                    >
                      <StyledText
                        level="body-sm"
                        fontSize={16}
                        fontWeight={600}
                        color={palette.primaryDark}
                      >
                        {expanded === ticket.id.toString() ? (
                          <Box>
                            <ExpandMoreIcon
                              sx={{
                                transform: "rotate(45deg)",
                                transition: "0.2s",
                              }}
                            />
                            {t("event_form_fields.hide_form_fields")}
                          </Box>
                        ) : (
                          <Box>
                            <ExpandMoreIcon />
                            {t("event_form_fields.view_form_fields")}
                          </Box>
                        )}
                      </StyledText>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div>
                        <StyledText
                          level="body-sm"
                          fontSize={16}
                          color={palette.charcoal}
                        >
                          {t("event_form_fields.ticket_number_description", {
                            number: index + 1,
                          })}
                        </StyledText>
                        <StyledText
                          color={palette.errorMain}
                          level="body-sm"
                          fontSize={13}
                          fontWeight={600}
                          sx={{
                            mb: 0.5,
                          }}
                        >
                          {t("form.required_description")}
                        </StyledText>
                        {formFields.map((field) => createField(field, formik))}
                        {!hideSubmitButton && (
                          <Box
                            sx={{
                              mt: 2,
                              display: "flex",
                              justifyContent: "flex-end",
                              width: "100%",
                            }}
                          >
                            <StyledButton
                              onClick={formik.handleSubmit}
                              size="sm"
                              fs={16}
                              bgColor={palette.black}
                              color={palette.white}
                              disabled={!formik.isValid}
                            >
                              {t("event_form_fields.save_response")}
                            </StyledButton>
                          </Box>
                        )}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                );
              }}
            </Formik>
          );
        })}
      </AccordionGroup>
    </Box>
  );
};

export default React.memo(EditFormFieldResponse);
