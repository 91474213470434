import { toast } from "react-toastify";
import { ROUTES } from "../routes/def";

/**
 * Handles unauthorized access (401) by redirecting to login
 */
export const handleUnauthorized = (toLoginPage: boolean = true) => {
  setTimeout(() => {
    toast.error("Please login to access this page!");
  }, 400);
  if (toLoginPage) {
    window.location.assign(ROUTES.LOGIN);
  }
};

/**
 * Handles forbidden access (403) by going back to previous page
 */
export const handleForbidden = (backToPreviousPage: boolean = true) => {
  setTimeout(() => {
    toast.error("You don't have permission to access this resource");
  }, 400);
  if (backToPreviousPage) {
    window.history.back();
  }
};

/**
 * Generic error handler for API responses
 * @param error - The error object from the API response
 * @param defaultMessage - Default message to show if no error message is provided
 */
export const handleApiError = (
  error: any,
  defaultMessage: string = "Something went wrong",
  backToPreviousPage: boolean = true
) => {
  if (error.response?.status === 401) {
    handleUnauthorized(backToPreviousPage);
  } else if (error.response?.status === 403) {
    handleForbidden(backToPreviousPage);
  } else {
    toast.error(error.response?.data?.error || defaultMessage);
  }
};
