import { Fragment, useEffect } from "react";
import StyledText from "../text/styled_text";
import { Box, Button, Link } from "@mui/joy";
import usePalette from "../../theme/use_palette";

declare global {
  interface Window {
    displayPreferenceModal?: () => void;
  }
}

const ConsentPreferences = () => {
  const palette = usePalette();

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (window.displayPreferenceModal) {
      window.displayPreferenceModal();
    }
  };

  return (
    <Fragment>
      <a
        href="#"
        onClick={handleClick}
        className="termly-display-preferences"
        style={{
          textDecoration: "underline",
          color: palette.textSecondary,
          fontSize: 16,
          fontFamily: "Libre Franklin",
        }}
      >
        Consent Preferences
      </a>
    </Fragment>
  );
};

export default ConsentPreferences;
