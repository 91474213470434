// BottomCart.tsx
import React from "react";
import { Box, Button, Divider, Stack } from "@mui/joy";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../store";
import { IEvent, ITicketRelease } from "../../../../types";
import { useTranslation } from "react-i18next";
import usePalette from "../../../../theme/use_palette";
import { motion } from "framer-motion";
import { openCartModal } from "../../../../redux/features/ticketOrderSlice";
import StyledText from "../../../text/styled_text";
import { adjustTransparency } from "../../../../utils/manager/color";
import useShowMobileNav from "../../../../hooks/nav/show_mobile_nav_hook";
import StyledButton from "../../../buttons/styled_button";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import {
  calculateTicketFee,
  calculateTotalPrice,
} from "../../../../utils/ticket_types";
import { requiresUpfrontPayment } from "../../../../utils/manage_event/can_edit_payment_deadline";

interface BottomCartProps {
  ticketRelease: ITicketRelease;
  event: IEvent;
  onProceed: (
    ticketsData: {
      ticket_type_id: number;
      addons: {
        id: number;
        quantity: number;
      }[];
    }[]
  ) => void;
}

const BottomCart: React.FC<BottomCartProps> = ({
  ticketRelease,
  event,
  onProceed,
}) => {
  const { items } = useSelector((state: RootState) => state.ticketOrder);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const palette = usePalette();

  const isMobile = useShowMobileNav();

  // Filter items for the current ticket release
  const cartItemsForRelease = items.filter(
    (item) => item.ticket.ticket_release_id === ticketRelease.id
  );

  // Calculate total amount including addons
  const totalAmount = cartItemsForRelease.reduce((total, item) => {
    const ticketPrice = item.ticket.price;
    const addonsPrice = item.addons.reduce((sum, addon) => {
      const addonInfo = ticketRelease.add_ons?.find((a) => a.id === addon.id);
      return sum + (addonInfo ? addonInfo.price * addon.quantity : 0);
    }, 0);
    return total + ticketPrice + addonsPrice;
  }, 0);

  // Check if all tickets are free and there are no paid addons
  const allTicketsAreFree = cartItemsForRelease.every(
    (item) => item.ticket.price === 0 && item.addons.length === 0
  );

  const ticketSummary = cartItemsForRelease.reduce((acc, item) => {
    const typeName = item.ticket.name;
    if (!acc[typeName]) {
      acc[typeName] = {
        count: 0,
        price: item.ticket.price,
      };
    }
    acc[typeName].count += 1;
    return acc;
  }, {} as Record<string, { count: number; price: number }>);

  const cartIsEmpty = cartItemsForRelease.length === 0;

  const handleButtonClick = () => {
    if (totalAmount === 0) {
      // For free tickets, directly proceed without showing the cart modal
      const ticketsData = cartItemsForRelease.map((item) => ({
        ticket_type_id: item.ticket.id,
        addons: item.addons,
      }));
      onProceed(ticketsData);
    } else {
      // Show cart modal for paid tickets
      dispatch(openCartModal());
    }
  };

  const isPayDirect = requiresUpfrontPayment(
    ticketRelease?.ticket_release_method_detail.ticket_release_method
  );

  return (
    <motion.div
      initial={{ y: "100%", x: "-50%", opacity: 0 }}
      animate={{ y: cartIsEmpty ? "100%" : "0%", opacity: cartIsEmpty ? 0 : 1 }}
      transition={{ type: "spring", stiffness: 220, damping: 20 }}
      style={{
        position: "fixed",
        bottom: isMobile ? "64px" : "16px",
        left: "50%",
        transform: "translateX(-50%)",
        width: isMobile ? "calc(100% - 32px)" : "100%",
        maxWidth: "600px",
        backgroundColor: adjustTransparency(palette.black, 0.85),
        padding: totalAmount > 0 ? "16px 16px 4px 16px" : "16px",
        borderRadius: "16px",
        boxShadow: "0 -2px 10px rgba(0,0,0,0.1)",
        zIndex: 1300,
      }}
    >
      {!isMobile && (
        <Box
          mb={1}
          sx={{
            maxHeight: "150px",
            overflowY: "auto",
            pr: 1,
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-track": {
              background: "transparent",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: adjustTransparency(palette.white, 0.3),
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: adjustTransparency(palette.white, 0.5),
            },
            scrollbarWidth: "thin",
            scrollbarColor: `${adjustTransparency(
              palette.white,
              0.3
            )} transparent`,
          }}
        >
          <Stack direction="column" spacing={0.5} mb={1}>
            {Object.entries(ticketSummary).map(([type, { count, price }]) => (
              <Box key={type}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <StyledText
                    level="body-sm"
                    color={palette.white}
                    fontWeight={400}
                    fontSize={16}
                  >
                    {count}x {type}
                  </StyledText>
                  {!allTicketsAreFree && (
                    <StyledText
                      level="body-sm"
                      color={palette.white}
                      fontWeight={400}
                      fontSize={16}
                    >
                      {(price * count).toFixed(2)} kr
                    </StyledText>
                  )}
                </Box>

                {/* Display addons for each ticket */}
                {cartItemsForRelease
                  .filter((item) => item.ticket.name === type)
                  .map(
                    (item, index) =>
                      item.addons.length > 0 && (
                        <Box key={index} ml={2}>
                          {item.addons.map((addon) => {
                            const addonInfo = ticketRelease.add_ons?.find(
                              (a) => a.id === addon.id
                            );
                            return (
                              <Box
                                key={addon.id}
                                display="flex"
                                justifyContent="space-between"
                                sx={{ mt: 0.5 }}
                              >
                                <Stack direction="row" spacing={1}>
                                  <AddCircleOutlineIcon
                                    sx={{ color: palette.white, fontSize: 16 }}
                                  />
                                  <StyledText
                                    level="body-sm"
                                    color={palette.white}
                                    fontSize={14}
                                  >
                                    {addon.quantity}x {addonInfo?.name}
                                  </StyledText>
                                </Stack>
                                {!allTicketsAreFree && (
                                  <StyledText
                                    level="body-sm"
                                    color={palette.white}
                                    fontSize={14}
                                  >
                                    {(
                                      (addonInfo?.price || 0) * addon.quantity
                                    ).toFixed(2)}{" "}
                                    kr
                                  </StyledText>
                                )}
                              </Box>
                            );
                          })}
                        </Box>
                      )
                  )}
              </Box>
            ))}
          </Stack>
        </Box>
      )}
      {!isMobile && !allTicketsAreFree && (
        <Divider
          sx={{ backgroundColor: palette.white, width: "100%", mb: 1 }}
        />
      )}

      <Box display="flex" alignItems="center" justifyContent="space-between">
        {!allTicketsAreFree ? (
          <>
            <Stack direction="row" spacing={1} alignItems="center">
              <StyledText
                level="body-md"
                color={palette.white}
                fontWeight={600}
                fontSize={isMobile ? 16 : 22}
                startDecorator={
                  <ShoppingCartIcon
                    sx={{
                      color: palette.primaryMain,
                      fontSize: isMobile ? 20 : 30,
                    }}
                  />
                }
              >
                {t("event.ticket_release.checkout.total")}:
              </StyledText>
              <StyledText
                level="body-md"
                color={palette.white}
                fontWeight={700}
                fontSize={isMobile ? 16 : 22}
              >
                {calculateTotalPrice(
                  totalAmount,
                  totalAmount === 0
                    ? ""
                    : event?.details?.user_pays_ticket_fee
                    ? event?.details?.fee_transaction_plan || "TSSR_STANDARD"
                    : "",
                  event?.details?.vat || 0
                ).toFixed(2)}
                kr
              </StyledText>
            </Stack>

            <StyledButton
              size="md"
              onClick={handleButtonClick}
              disabled={cartIsEmpty}
              bgColor={palette.primaryMain}
              color={palette.alwaysWhite}
              fs={isMobile ? 14 : 16}
            >
              {t("event.ticket_release.checkout.view_cart")}
            </StyledButton>
          </>
        ) : (
          <>
            <Stack direction="row" spacing={1} alignItems="center">
              <StyledText
                level="body-md"
                color={palette.white}
                fontWeight={600}
                fontSize={isMobile ? 16 : 22}
                startDecorator={
                  <HowToRegIcon
                    sx={{
                      color: palette.primaryMain,
                      fontSize: isMobile ? 20 : 30,
                    }}
                  />
                }
              >
                {isPayDirect
                  ? t("event.ticket_release.checkout.complete_signup")
                  : t("event.ticket_release.checkout.proceed_request")}
              </StyledText>
            </Stack>
            <StyledButton
              size="md"
              onClick={handleButtonClick}
              disabled={cartIsEmpty}
              bgColor={palette.successDark}
              color={palette.white}
              fs={isMobile ? 14 : 16}
              startDecorator={<HowToRegIcon />}
            >
              {isPayDirect
                ? t("event.ticket_release.checkout.register")
                : t("event.ticket_release.checkout.proceed_request")}
            </StyledButton>
          </>
        )}
      </Box>
      {totalAmount > 0 && (
        <StyledText
          level="body-xs"
          color={palette.white}
          sx={{
            opacity: 0.8,
            fontSize: isMobile ? 12 : 13,
            m: 0,
            textAlign: "center",
          }}
        >
          {t("event.ticket_release.checkout.taxes_fees_next_step")}
        </StyledText>
      )}
    </motion.div>
  );
};

export default BottomCart;
