import React from "react";
import StyledText from "../../../text/styled_text";
import usePalette from "../../../../theme/use_palette";
import { Box, Grid, Link, Stack } from "@mui/joy";
import { ITicket } from "../../../../types";
import { useTranslation } from "react-i18next";
import QRCode, { QRCodeCanvas } from "qrcode.react";
import GoogleWalletIcon from "../../../../assets/icons/google-wallet-icon.svg";
import StyledButton from "../../../buttons/styled_button";

interface TicketQRCodeProps {
  ticket: ITicket; // replace with your actual ticket type
}

const TicketQRCode: React.FC<TicketQRCodeProps> = ({ ticket }) => {
  const { t } = useTranslation();
  const palette = usePalette();

  return (
    <Box>
      <StyledText
        level="body-sm"
        fontSize={18}
        color={palette.primaryDark}
        fontWeight={700}
        style={{
          textAlign: "center",
        }}
      >
        {ticket.ticket_type.name}
      </StyledText>
      <Box
        sx={{
          backgroundColor: palette.alwaysWhite,
          padding: "16px",
          borderRadius: "8px",
          width: "fit-content",
          margin: "16px auto",
        }}
      >
        {ticket.checked_in && (
          <StyledText
            level="body-sm"
            fontSize={24}
            color={palette.successLight}
            fontWeight={700}
            style={{
              position: "absolute",
              zIndex: 100,
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
              width: "200px",
              textShadow: "0 0 8px black",
            }}
          >
            {t("tickets.qr_code.already_checked_in")}
          </StyledText>
        )}

        <QRCodeCanvas
          value={ticket.qr_code}
          size={200}
          title={ticket.id.toString()}
          level="H"
          style={{
            filter: ticket.checked_in ? "blur(8px)" : "none",
          }}
        />
      </Box>
      <StyledText
        level="body-sm"
        fontSize={16}
        color={palette.greyLight}
        style={{
          textAlign: "center",
        }}
      >
        ID: {ticket.id}
      </StyledText>
      {ticket.google_wallet_url && (
        <Box
          sx={{
            textAlign: "center",
            my: 1,
          }}
        >
          <StyledButton
            size="sm"
            bgColor="transparent"
            color={palette.black}
            fs={16}
            endDecorator={
              <img
                src={GoogleWalletIcon}
                alt="Google Wallet"
                style={{
                  width: "25px",
                  height: "auto",
                  margin: "0 auto",
                }}
              />
            }
          >
            <Link
              href={ticket.google_wallet_url}
              target="_blank"
              sx={{
                color: palette.black,
              }}
            >
              {t("tickets.qr_code.wallet.add_to_google_wallet")}
            </Link>
          </StyledButton>
        </Box>
      )}

      <StyledText
        level="body-sm"
        fontSize={16}
        color={palette.textSecondary}
        sx={{
          textWrap: "balance",
        }}
        style={{ marginTop: "8px", textAlign: "center" }}
      >
        {t("tickets.qr_code.description")}
      </StyledText>
    </Box>
  );
};

export default TicketQRCode;
