// WelcomeMessage component
import { Box, Stack } from "@mui/joy";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import usePalette from "../../../theme/use_palette";
import StyledText from "../../text/styled_text";
import { format } from "date-fns";

const WelcomeMessage = () => {
  const palette = usePalette();
  const { t } = useTranslation();

  const variants = {
    hidden: { opacity: 0, y: 40 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={variants}
      transition={{ duration: 0.5 }}
    >
      <Box
        sx={{
          mb: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 1 }}>
          <StyledText level="body-sm" fontWeight={600} color={palette.charcoal}>
            {t("manager.ai.welcome_message.assistant_name")}
          </StyledText>
          <StyledText level="body-sm" color={palette.greyLight} fontSize={13}>
            {format(new Date(), "dd/MM/yyyy HH:mm")}
          </StyledText>
        </Stack>

        <Box
          sx={{
            p: 2,
            bgcolor: palette.offWhite,
            borderRadius: 8,
            maxWidth: "85%",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
          }}
        >
          <StyledText level="body-md" fontSize={15}>
            {t("manager.ai.welcome_message.greeting")}
            <ul
              style={{
                marginTop: "8px",
                paddingLeft: "20px",
                listStyle: "none",
              }}
            >
              <li>• {t("manager.ai.welcome_message.features.events")}</li>
              <li>• {t("manager.ai.welcome_message.features.tickets")}</li>
              <li>• {t("manager.ai.welcome_message.features.food")}</li>
              <li>• {t("manager.ai.welcome_message.features.marketing")}</li>
            </ul>
            {t("manager.ai.welcome_message.language_support")}
          </StyledText>
        </Box>
      </Box>
    </motion.div>
  );
};

export default WelcomeMessage;
