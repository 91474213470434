import React from "react";
import { Box, FormControl, Select, Option } from "@mui/joy";
import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store";
import { IChatRequest } from "../../../../types";
import { sendMessageRequest } from "../../../../redux/features/manager/ai/aiChatSlice";
import usePalette from "../../../../theme/use_palette";
import { FormTextarea } from "../../../forms/input_types";
import { StyledErrorMessage } from "../../../forms/messages";
import StyledButton from "../../../buttons/styled_button";
import SendIcon from "@mui/icons-material/Send";

const validationSchema = yup.object().shape({
  organization_id: yup.number().required("Organization is required"),
  message: yup.string().required("Message is required"),
});

interface SidebarChatInputFormProps {
  organizationId?: number;
  eventId?: number;
}

const SidebarChatInputForm: React.FC<SidebarChatInputFormProps> = ({
  organizationId,
  eventId,
}) => {
  const { t } = useTranslation();
  const palette = usePalette();
  const dispatch: AppDispatch = useDispatch();

  const { loading, currentSession } = useSelector(
    (state: RootState) => state.aiChat
  );
  const { organizations } = useSelector(
    (state: RootState) => state.organization
  );

  const handleSubmit = async (values: IChatRequest, { resetForm }: any) => {
    await dispatch(
      sendMessageRequest({
        ...values,
        event_id: eventId,
      })
    );
    // Reset only the message field while keeping other values
    resetForm({
      values: {
        ...values,
        message: "",
      },
    });
  };
  return (
    <Formik
      initialValues={{
        message: "",
        session_type: "event_generation",
        session_id: currentSession?.id,
        organization_id: organizationId || organizations?.[0]?.id || 0,
        event_id: eventId || undefined,
      }}
      validationSchema={validationSchema}
      validateOnChange={true}
      validateOnBlur={true}
      enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, setFieldValue, values, isValid, submitForm }) => (
        <Form>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <FormTextarea
              label=""
              name="message"
              placeholder={t("manager.ai.message_placeholder") as string}
              minRows={2}
              maxRows={5}
              maxChars={5000}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (!e.ctrlKey && !e.shiftKey) {
                    e.preventDefault();
                    submitForm();
                  } else {
                    setFieldValue("message", `${values.message}\n`);
                  }
                }
              }}
            />

            <StyledButton
              size="md"
              type="submit"
              disabled={loading || isSubmitting || !isValid}
              bgColor={palette.black}
              color={palette.white}
              fs={16}
            >
              <SendIcon />
            </StyledButton>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default SidebarChatInputForm;
